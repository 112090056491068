import UsePageNavigation from '../../../../../components/sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { chatPageNavigation } from '../../../../../components/staticComponents/StaticHtmlGenerator';
import BasicPopover from '../../../../../components/sharedComponents/BasicPopover';
import {
  Box,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ImageButton from '../../../../../components/sharedComponents/ImageButton';
import { buttonStyle } from '../../dataset/Dataset';
import * as assets from '../../../../../assets';
import Api from '../../../../../data/api/Api';
import EditTeam from '../editTeam/EditTeam';
import { activeTeamMethod, refreshTeamsList } from '../../../../../reduxStore/slices/TeamsSlice';
import { lengthOfVisibleAgentOrTeamName } from '../../agents/myAgents/MyAgents';
import { setMaxRoundsForTeams } from '../../../../../reduxStore/slices/CommonSlice';
import useResetActiveItems from '../../../../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';
import { ActiveNavItemContext } from '../../../../../components/rightPanel/RightPanelContext';

export interface MyTeamsData {
  name: string;
  _id: string;
  description: string;
  assistant_ids: string[];
  owners: string[];
  acl?: {
    users: string[];
  };
}

interface MyTeamsDataResponse {
  data: MyTeamsData[];
  response: Response;
}

function MyTeams() {
  const [myTeams, setMyTeams] = useState<MyTeamsData[]>([]);
  const [filteredMyTeamsList, setFilteredMyTeamsList] = useState<MyTeamsData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { activeTeam, refetchTeamsList } = useAppSelector((state) => state.teams);
  const { t } = useTranslation();
  const { isWelcomePage } = UsePageNavigation();
  const navigate = useNavigate();
  const { activeNavItem } = useContext(ActiveNavItemContext);
  const resetActiveItems = useResetActiveItems();

  // UseEffect hook to fetch the list of Teams on component mount
  useEffect(() => {
    fetchMyTeamsList();
  }, []);

  // Function to refresh the list of Teams.
  useEffect(() => {
    if (refetchTeamsList) {
      fetchMyTeamsList();
      dispatch(refreshTeamsList(false));
    }
  }, [refetchTeamsList]);

  // Function to fetch the list of teams from the API
  const fetchMyTeamsList = () => {
    Api.getMyTeamsList()
      .then((response) => {
        setLoading(false);
        const myTeamsListResp = response as MyTeamsDataResponse;
        const sortedTeams = myTeamsListResp.data.sort((a, b) => a.name.localeCompare(b.name));
        setMyTeams(sortedTeams);
        setFilteredMyTeamsList(sortedTeams);

        //if the team is active and if its data gets updated then make sure
        //to update the active team data too.
        if (activeTeam._id) {
          dispatch(activeTeamMethod(sortedTeams.find((team) => team._id === activeTeam._id) as MyTeamsData));
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error in fetching my teams list:', error);
      });
  };

  // Function to set the Teams active
  const makeTeamActive = async (teamsData: MyTeamsData) => {
    await resetActiveItems(activeNavItem);
    dispatch(activeTeamMethod(teamsData));
    dispatch(showUserActionContentOnChat(t('rightPanel.team.activateTeam', { activeTeamName: teamsData.name })));
    dispatch(setMaxRoundsForTeams(teamsData.assistant_ids.length));

    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };

  // Function to filter the list of teams based on the search query
  const filterMyTeamsList = (teamName: string) => {
    setFilteredMyTeamsList(myTeams.filter((team) => team.name.toLowerCase().includes(teamName.toLowerCase())));
  };

  const renderEditTeamPopover = (team: MyTeamsData) => {
    return (
      <BasicPopover
        srcComponent={
          <Box className="hover-showDotsButton">
            <ImageButton srcFile={assets.dots} style={buttonStyle} />
          </Box>
        }
        contentComponent={<EditTeam team={team} isActiveTeam={false} />}
      />
    );
  };

  return (
    <Box>
      <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
        {t('rightPanel.team.myTeams')}
      </Typography>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Box>
          {!myTeams.length ? (
            <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
              {t('rightPanel.team.noCustomTeams')}
            </Typography>
          ) : (
            <>
              <TextField
                label={t('rightPanel.team.searchTeamName')}
                variant="standard"
                id="searchTeam"
                onChange={(event) => filterMyTeamsList(event.target.value)}
                size="small"
                fullWidth
              />
              <List sx={{ maxHeight: '35rem', overflow: 'auto' }}>
                {filteredMyTeamsList.map((team) => (
                  <Box key={team._id} className="teams-list">
                    <ListItemButton
                      disabled={activeTeam._id === team._id}
                      sx={{
                        padding: '5px 10px',
                        // paddingLeft: '0px',
                      }}
                    >
                      {/* <ListItemAvatar sx={{ minWidth: '50px' }} onClick={() => makeTeamActive(team)}>
                        <Avatar alt="default team" src={assets.ai360Agent} />
                      </ListItemAvatar> */}
                      <Tooltip title={team.name.length > lengthOfVisibleAgentOrTeamName ? team.name : null} arrow>
                        <ListItemText
                          primary={team.name}
                          sx={{
                            padding: '8px 0px',
                            margin: '0px',
                            '& .MuiListItemText-primary': {
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          onClick={() => makeTeamActive(team)}
                        />
                      </Tooltip>
                      {activeTeam._id !== team._id && (
                        <span className="buttonShowOnHover">{renderEditTeamPopover(team)}</span>
                      )}
                    </ListItemButton>
                  </Box>
                ))}
                {filteredMyTeamsList.length === 0 && <Typography color="red">{t('rightPanel.noResult')}</Typography>}
              </List>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default MyTeams;
