import { AppSkillId } from '../../../../components/staticComponents/StaticHtmlGenerator';
import { SowCommitAiQueryResponse } from '../../../../reduxStore/slices/CustomAppsSlice';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export function SowResponseTable({
  sowQueryResponse,
  selectedCustomAppSkills,
}: {
  sowQueryResponse: any;
  selectedCustomAppSkills: string;
}) {
  return (
    <>
      {(selectedCustomAppSkills === AppSkillId.SOWCommitAI ||
        selectedCustomAppSkills === AppSkillId.SOWCommitAccAI) && (
        <Table sx={{ border: '1px solid black', marginBottom: '8px' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Sl. No.</TableCell>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Check Commitment</TableCell>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Available Commitments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sowQueryResponse.map((sowResponseList: SowCommitAiQueryResponse, index: number) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid black' }}>{index}</TableCell>
                <TableCell sx={{ border: '1px solid black' }}>{sowResponseList.row.title}</TableCell>
                <TableCell sx={{ border: '1px solid black' }}>{sowResponseList.row.GenAI}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {selectedCustomAppSkills === AppSkillId.SOWPredictor && (
        <Table sx={{ border: '1px solid black', marginBottom: '8px' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Attribute</TableCell>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Values</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(sowQueryResponse).map((item: any, index: number) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid black' }}>{item[0]}</TableCell>
                <TableCell sx={{ border: '1px solid black' }}>{item[1]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {selectedCustomAppSkills === AppSkillId.AdvancedSummary && sowQueryResponse.results && (
        <Table sx={{ border: '1px solid black', marginBottom: '8px' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Default Summary</TableCell>
              <TableCell sx={{ fontWeight: '700', border: '1px solid black' }}>Advanced Summary</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{ width: '53%', verticalAlign: 'text-top', textAlign: 'justify', border: '1px solid black' }}
              >
                <Markdown remarkPlugins={[remarkGfm]}>{sowQueryResponse.results.doc_summary}</Markdown>
              </TableCell>
              <TableCell
                sx={{ width: '53%', verticalAlign: 'text-top', textAlign: 'justify', border: '1px solid black' }}
              >
                <Markdown remarkPlugins={[remarkGfm]}>{sowQueryResponse.results.adv_summary}</Markdown>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
}

export default SowResponseTable;
