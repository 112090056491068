import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import Api from '../../../../data/api/Api';
import { useAppSelector, useAppDispatch } from '../../../../reduxStore/redux-hooks';
import { setInitialHistoryState } from '../../../../reduxStore/slices/historySlice';
import ChatHistoryList from './chatHistoryList/ChatHistoryList';
import { useTranslation } from 'react-i18next';
import { selectedDataSet } from '../../../../reduxStore/slices/DataSetSlice';
import { chatPageNavigation, SectionDivider, PartyType } from '../../../staticComponents/StaticHtmlGenerator';
import { useLocation } from 'react-router-dom';
import { AppSkillId } from '../../../../components/staticComponents/StaticHtmlGenerator';
import { UseTrackedNavigate } from '../../../../components/sharedComponents/customHooks/useTrackedNavigate/UseTrackedNavigate';
import { resetActiveAgent } from '../../../../reduxStore/slices/AgentsSlice';
import { resetActiveTeam } from '../../../../reduxStore/slices/TeamsSlice';
import { setClearChat } from '../../../../reduxStore/slices/CommonSlice';

export interface ConversationData {
  conversation_id: string;
  last_updated: number;
  party_id: string;
  title: string;
}

interface Acl {
  users: string[]; // Array of user IDs
}
export interface Dataset {
  _id: string;
  name: string;
  owners: string[];
  acl: Acl;
}

export interface ConversationResponse {
  data: ConversationData[];
  response: Response;
}

export interface HistoryData {
  content: string;
  conversed_time: number;
  media: string;
  message_index: number;
  name: string;
  role: string;
}
export interface HistoryResponse {
  data: HistoryData[];
  response: Response;
}

const History = () => {
  const { t } = useTranslation();
  const navigate = UseTrackedNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [conversationData, setConversationData] = useState<ConversationData[]>([]);
  const [allConversationsData, setAllConversationsData] = useState<ConversationData[]>([]);
  const [enableLoader, setEnableLoader] = useState(false);
  const { historyState, refreshTitleFlag } = useAppSelector((state) => state.history);
  const { activeDataSetId } = useAppSelector((state) => state.dataset.selectedDataSet);
  const { activeTeam } = useAppSelector((state) => state.teams);
  const { activeAgent } = useAppSelector((state) => state.agents);
  const fetchTitleLimitCount = 50;
  const currentPage = 0;

  const getTitles = async () => {
    let listOfConversations: ConversationData[] = [];
    setEnableLoader(true);
    try {
      const allHistoryTitle = await Promise.all([
        await Api.fetchTitles(currentPage, fetchTitleLimitCount, PartyType.Unknown),
        await Api.fetchTitles(currentPage, fetchTitleLimitCount, PartyType.Agent),
        await Api.fetchTitles(currentPage, fetchTitleLimitCount, PartyType.Team),
      ]);
      const historyTitles = allHistoryTitle.map((historyTitle) => historyTitle.data);
      listOfConversations = historyTitles.flat() as ConversationData[];
    } catch {
      throw Error(t('history.errorMessage'));
    }
    const sortedConversationList = listOfConversations.sort((a, b) => b.last_updated - a.last_updated);
    setConversationData(sortedConversationList);
    setAllConversationsData(sortedConversationList);
    setEnableLoader(false);
  };

  useEffect(() => {
    getTitles();
  }, [historyState.activeHistory, refreshTitleFlag]);

  const isDateToday = (dateToCheck: Date) => {
    const today = new Date();
    return (
      dateToCheck.getDate() === today.getDate() &&
      dateToCheck.getMonth() === today.getMonth() &&
      dateToCheck.getFullYear() === today.getFullYear()
    );
  };

  const filterHistoryBySearchTerm = (historyName: string) => {
    setConversationData(
      !historyName
        ? allConversationsData
        : allConversationsData.filter((item) => item.title.toLowerCase().includes(historyName))
    );
  };

  const isWithinLast7DaysButNotToday = (dateToEvaluate: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today to the start of the day for accurate comparison
    const oneWeekAgo = new Date().setDate(today.getDate() - 7);
    return (
      dateToEvaluate.getTime() < today.getTime() &&
      dateToEvaluate.getTime() >= oneWeekAgo &&
      !isDateToday(dateToEvaluate)
    );
  };

  const fetchAndProcessConversationMessages = async (
    conversationId: string,
    conversationHeading: string,
    party_id: string
  ) => {
    if (location.pathname.toLowerCase() !== chatPageNavigation.toLowerCase()) {
      navigate(chatPageNavigation);
    }
    dispatch(setClearChat(true)); //Clearing the Screen before user load any history.
    const conversationMessagesResponse = (await Api.fetchMessages(conversationId)) as HistoryResponse;
    const agentIdentify = party_id === '' ? PartyType.Unknown : PartyType.Agent;
    const modelDatasetIDSlice = conversationMessagesResponse.data[conversationMessagesResponse.data.length - 1].name;
    await setActiveModelAndDatasetFromMessage(modelDatasetIDSlice);
    dispatch(
      setInitialHistoryState({
        historyResponse: conversationMessagesResponse.data,
        activeHistory: conversationHeading,
        clearValue: false,
        conversationID: conversationId,
        conversationMessageCounter: conversationMessagesResponse.data.length,
        agentIdentifier: agentIdentify,
      })
    );
  };

  const setActiveModelAndDatasetFromMessage = async (modelDatasetIDSlice: string) => {
    const messageComponents = modelDatasetIDSlice.split(':');
    const modelFromResponse = messageComponents[0];
    const activeDatasetId = messageComponents[2];
    dispatch(
      setInitialHistoryState({
        selectedModelHistory: modelFromResponse,
      })
    );
    if (activeDatasetId) {
      const listDatasetsResponse = await Api.listDatasets(true, true, AppSkillId.DocCompletion);
      const fetchedDatasets = listDatasetsResponse.data as Dataset[];
      const activeDataset = fetchedDatasets.find((dataset) => dataset._id === activeDatasetId);
      if (activeDataset) {
        dispatch(
          selectedDataSet({
            activeDataSetId: activeDatasetId,
            activeDataSetName: activeDataset.name as string,
          })
        );
        if (activeAgent._id) {
          dispatch(resetActiveAgent());
        }
        if (activeTeam._id) {
          dispatch(resetActiveTeam());
        }
      }
    } else {
      if (activeDataSetId) {
        dispatch(
          selectedDataSet({
            activeDataSetId: '',
            activeDataSetName: '',
          })
        );
      }
    }
  };

  const todayHistoryData = conversationData.filter((conversation) =>
    isDateToday(new Date(conversation.last_updated * 1000))
  );
  const last7DaysHistoryData = conversationData.filter((conversation) =>
    isWithinLast7DaysButNotToday(new Date(conversation.last_updated * 1000))
  );

  return (
    <Box>
      <Box sx={{ paddingBottom: '1rem' }}>
        <Box>
          <TextField
            label="Search"
            variant="standard"
            onChange={(event) => filterHistoryBySearchTerm(event.target.value)}
            size="small"
            fullWidth
          />
        </Box>
      </Box>
      <Box>
        {historyState.activeHistory && (
          <>
            <Box>
              <Box>
                <Typography sx={{ fontWeight: 700, color: '#6F6C6C' }}>{t('history.activeHistorySession')}</Typography>
                <Typography
                  sx={{
                    paddingTop: '1rem',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '18.75px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '275px',
                  }}
                >
                  {historyState.activeHistory}
                </Typography>
              </Box>
            </Box>
            <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '30px', marginTop: '0px' }} />
          </>
        )}

        <Box>
          <Box>
            <Typography sx={{ fontWeight: 700, color: '#6F6C6C' }}>{t('history.todayHistory')}</Typography>
            <ChatHistoryList
              enableLoader={enableLoader}
              historyData={todayHistoryData}
              fetchAndProcessConversationMessages={fetchAndProcessConversationMessages}
              historyDayIdentifier={t('history.todayHistory')}
            />
          </Box>
        </Box>
        <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '25px', marginTop: '0px' }} />

        <Box>
          <Box>
            <Typography sx={{ fontWeight: 700, color: '#6F6C6C', marginBottom: '1rem' }}>
              {t('history.lastSevenDaysHistory')}
            </Typography>
            <Box>
              <ChatHistoryList
                enableLoader={enableLoader}
                historyData={last7DaysHistoryData}
                fetchAndProcessConversationMessages={fetchAndProcessConversationMessages}
                historyDayIdentifier={t('history.lastSevenDaysHistory')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default History;
