import {
  Avatar,
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as assets from '../../assets';

const serviceNowLink =
  'https://wiprocio.service-now.com/sp?id=sc_cat_item&sys_id=0b5d34bfdbc160501d907c63f3961951&sysparm_function=08d86756db7b9c101d907c63f396197a&sysparm_subfunction=840b316e0b1bddd4060e8fcfd5f8df23&sysparm_type=other';

export const NoAccessToInnovationCenterHTML = () => {
  return (
    <Box className="msgBox">
      <Box className="welcome-icon">
        <img id="changeicon" src={assets.chatGpt_logo} alt="chat gpt logo" />
        <Box className="toolTip">
          <strong>3.5</strong>
        </Box>
      </Box>
      <Box className="chat-content" sx={{ '& p': { marginTop: 0 } }}>
        <Typography marginBottom={'0.5rem'}>
          Can&apos;t see this page? Let&apos;s get you access,{' '}
          <Link href={serviceNowLink} underline="hover" target="_blank">
            submit
          </Link>{' '}
          a ticket to view this content.
        </Typography>
        <Typography>Please choose:</Typography>
        <List sx={{ listStyleType: 'disc', padding: '0px', paddingLeft: '15px' }}>
          <ListItem sx={{ display: 'list-item', padding: '0px' }}>
            <ListItemText
              primary={
                <span>
                  <Typography component="span" sx={{ fontWeight: '700' }}>
                    Service Category:
                  </Typography>{' '}
                  ai360 platform
                </span>
              }
            />
          </ListItem>
          <ListItem sx={{ display: 'list-item', padding: '0px' }}>
            <ListItemText
              primary={
                <span>
                  <Typography component="span" sx={{ fontWeight: '700' }}>
                    Classification:
                  </Typography>{' '}
                  Access request
                </span>
              }
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

// Mapping nav item name to icon
export const NavItemImages: { [key: string]: string } = {
  bell: assets.bell,
  chat: assets.chatMenu,
  dataset: assets.datasets,
  agents: assets.agents,
  teams: assets.teams,
  history: assets.history,
  home: assets.home,
  setting: assets.setting,
  apps: assets.customApps,
  tooltip: assets.Tootip,
};

export const NAV_PANEL_WIDTH = 65;
export const SUB_PANEL_WIDTH = 352;
export const SUB_PANEL_WIDTH_SX = 287;
export const MAX_LENGTH_DATASET_NAME = 20;
export const MIN_LENGTH_DATASET_NAME = 0;
export const MAX_FILE_SIZE = 100;
export const FILE_COMPLITION_PERCENTAGE = 100;
export const AgentInstructionsMaxCharacterCount = 8192;
export const lengthOfVisibleAgentName = 15;

export const chatPageNavigation = '/Chat';
export const welcomePageNavigation = '/WelcomePage';
export const disclaimerPageNavigation = '/disclaimer';
export const loginPageNavigation = '/login';

// Google analytics related constant
export const gaMeasurementIDDev = 'G-CMD2X4M3QC';
export const gaMeasurementIDStaging = 'G-86Q4BW9MFT';
export const gaMeasurementIDProduction = 'G-QT4DDK9NFT';

export const stagingEnv = 'stagingapps';
export const prodEnv = 'apps';

export const askButtonText = 'Ask';
export const tabClickText = 'Tab Click';
export const buttonClickText = 'Button Click';
export const eventCategoryText = 'Event';
export const pageTypeText = 'Page';
export const pageViewText = 'pageview';

export const developerPortalLink = 'https://docs.lab45.ai/';

export enum CustomAppName {
  SOWPredictor = 'SOW Predictor',
  SOWCommitAI = 'SOW Commit AI',
  SOWFinAI = 'SOW Fin AI',
  QETPlatform = 'QET Platform',
  AdvancedSummary = 'Advanced Summary',
}
export enum AppSkillId {
  Completion = 'completion',
  DocCompletion = 'doc_completion',
  SOWPredictor = 'sow_predictor',
  SOWCommitAI = 'sow_commit_ai',
  SOWCommitAccAI = 'sow_commit_acc_ai',
  QETApp = 'qet_app',
  AdvancedSummary = 'advanced_summary',
}

//for custom apps json

export enum CustomAppJsonName {
  BiTSGpt = 'BiTS Helpdesk',
  HRGpt = 'HR Policy Queries',
  Velociq = 'Velociq Helpdesk',
  DomainConsultng = 'Domain & Consulting Center',
  TopsAirlines = 'TOPS Airlines helpdesk',
  SPGI = 'SPGI Helpdesk',
  GSG = 'GSG Policies Center',
}

export const customAppsJsonNames: (CustomAppJsonName | string)[] = [
  CustomAppJsonName.BiTSGpt,
  CustomAppJsonName.HRGpt,
  CustomAppJsonName.Velociq,
  CustomAppJsonName.TopsAirlines,
  CustomAppJsonName.SPGI,
  CustomAppJsonName.DomainConsultng,
  CustomAppJsonName.GSG,
];

export const customAppsWithSpecificSkills: (CustomAppName | string)[] = [
  CustomAppName.SOWPredictor,
  CustomAppName.SOWCommitAI,
  CustomAppName.SOWFinAI,
  CustomAppName.AdvancedSummary,
];

export const skillIdOfCustomApps: (AppSkillId | string)[] = [
  AppSkillId.SOWCommitAI,
  AppSkillId.SOWCommitAccAI,
  AppSkillId.QETApp,
  AppSkillId.SOWPredictor,
  AppSkillId.AdvancedSummary,
];
export const customAppsSkillIds: (AppSkillId | string)[] = [AppSkillId.SOWCommitAI, AppSkillId.SOWCommitAccAI];
export const maxOutputTokentooltipPoints = [
  'firstPoint',
  'secondPoint',
  'thirdPoint',
  'fourthPoint',
  'fifthPoint',
  'sixthPoint',
  'seventhPoint',
  'eigthPoint',
  'ninthPoint',
  'tenthPoint',
];

export const topKtooltipPoints = ['firstPoint', 'secondPoint', 'thirdPoint', 'fourthPoint', 'fifthPoint'];

export const hiddenMyAgentsList = ['logoassistant', 'bing', 'imageassistant'];

//Section divider
export function SectionDivider(props: { sx?: { [key: string]: string | number } }) {
  return (
    <Divider
      variant={'middle'}
      sx={{ borderStyle: 'dashed', borderColor: '#B3B9C4', height: '30px', ...props.sx }}
      aria-roledescription={'separator'}
    />
  );
}

/**
 * Check if the screen is portrait.
 */
export function usePortrait(): boolean {
  return useMediaQuery('(orientation: portrait)');
}

/**
 * Check if the main input can hover.
 */
export function useCanHover(): boolean {
  return !useMediaQuery('(hover: none)');
}

export function createMatchMedia(expectedQuery: string, matches: boolean): (query: string) => MediaQueryList {
  return (query) => {
    return {
      media: '',
      matches: query === expectedQuery && matches,
      onchange: () => {},
      dispatchEvent: () => {
        return false;
      },
      addListener: () => {},
      removeListener: () => {},
      addEventListener: () => {},
      removeEventListener: () => {},
    };
  };
}

//this if will be completely removed once we starting on implemeting custom apps
//in an organised way.
export const ActiveInnovationCenter = () => {
  return (
    <Box sx={{ padding: '10px' }}>
      <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
        Active
      </Typography>
      <List>
        <ListItemButton
          sx={{
            padding: '5px',
            paddingLeft: '0px',
            width: '100%',
          }}
        >
          <ListItemAvatar sx={{ alignSelf: 'start', minWidth: '50px' }}>
            <Avatar className="avatar" alt="default agent" src={assets.ai360Agent} />
          </ListItemAvatar>
          <Box width={'100%'}>
            <Typography color={'#0B0C0C'} fontWeight={700} fontSize={'16px'}>
              Innovation Center
            </Typography>
          </Box>
        </ListItemButton>
      </List>
    </Box>
  );
};

export const enabledProjectDeliveryFeatureTabs = ['Role Based', 'Accelerators'];

export const featuredList = [
  {
    featureId: 'manageService',
    featureName: 'Managed Service',
    featureBackgroundColor: '#EDF4FF',
    featureDisable: true,
    featureIcon: `${assets.codeWrite}`,
  },
  {
    featureId: 'projectDelivery',
    featureName: 'Project Delivery',
    featureBackgroundColor: '#EDF4FF',
    featureDisable: false,
    featureIcon: `${assets.codeWrite}`,
  },
  {
    featureId: 'innovation',
    featureName: 'Innovation Centers',
    featureBackgroundColor: '#F3FFF9',
    featureDisable: false,
    featureIcon: `${assets.brainstrom}`,
  },
  {
    featureId: 'coCreation',
    featureName: 'Co-creation workspace',
    featureBackgroundColor: '#FFF8F4',
    featureDisable: false,
    featureIcon: `${assets.summary}`,
  },
  {
    featureId: 'domain',
    featureName: 'Domain Solution',
    featureBackgroundColor: '#FFF3FC',
    featureDisable: true,
    featureIcon: `${assets.analyseData}`,
  },
];

export const projectDeliveryFeature = {
  associatedFeatureId: 'projectDelivery',
  associatedSubFeatures: [
    {
      label: 'Role Based',
      content: [
        {
          name: 'Software Engineer',
          description: 'Programmed to write, review, and maintain code, adhering to quality and performance standards',
          icon: `${assets.recentChat}`,
          enabled: true,
        },
        {
          name: 'Software Tester',
          description:
            'Generate test cases based on requirements and specifications, ensuring comprehensive test coverage.',
          icon: `${assets.recentChat}`,
          enabled: true,
        },
        {
          name: 'Devops Co-ordinator',
          description:
            'Manage and provision infrastructure resources, such as servers and cloud services, based on the needs.',
          icon: `${assets.recentChat}`,
          enabled: true,
        },
        {
          name: 'Database administrator',
          description:
            'Automate routine tasks, optimize performance, and ensure the security and integrity of database systems.',
          icon: `${assets.recentChat}`,
          enabled: true,
        },
      ],
    },
    {
      label: 'Accelerators',
      content: [
        {
          name: 'Database administrator',
          description: 'Programmed to write, review, and maintain code, adhering to quality and performance standards',
          icon: `${assets.recentChat}`,
          enabled: true,
        },
        {
          name: 'Software Tester',
          description:
            'Generate test cases based on requirements and specifications, ensuring comprehensive test coverage.',
          icon: `${assets.recentChat}`,
          enabled: true,
        },
        {
          name: 'Devops Co-ordinator',
          description:
            'Manage and provision infrastructure resources, such as servers and cloud services, based on the needs.',
          icon: `${assets.recentChat}`,
          enabled: true,
        },
        {
          name: 'Database administrator',
          description:
            'Automate routine tasks, optimize performance, and ensure the security and integrity of database systems.',
          icon: `${assets.recentChat}`,
          enabled: true,
        },
      ],
    },
  ],
};

export const coCreationFeature = {
  associatedFeatureId: 'coCreation',
  associatedSubFeatures: [
    {
      name: 'HR',
      description: 'Lorem Ipsum text',
      icon: `${assets.recentChat}`,
      enabled: true,
    },
    {
      name: 'Marketing',
      description: 'Lorem Ipsum text',
      icon: `${assets.recentChat}`,
      enabled: true,
    },
    {
      name: 'Finance',
      description: 'Lorem Ipsum text',
      icon: `${assets.recentChat}`,
      enabled: true,
    },
    {
      name: 'Sales',
      description: 'Lorem Ipsum text',
      icon: `${assets.recentChat}`,
      enabled: true,
    },
  ],
};

export const DeveloperKeyTooltip =
  'Note: \n To regenerate a new key.\n Step 1: Revoke the existing key \n Step 2: Generate new key';

export enum PartyType {
  Agent = 'Agent',
  Team = 'Team',
  Unknown = 'Unknown',
}

export const loggedInUserId = () => {
  const accountKeys = localStorage.getItem('msal.account.keys');
  let firstKey = null;
  if (accountKeys) {
    const parsedKeys = JSON.parse(accountKeys);
    if (Array.isArray(parsedKeys) && parsedKeys.length > 0) {
      firstKey = parsedKeys[0].split('.')[0].trim();
    }
  }
  return firstKey;
};
