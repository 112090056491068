import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import * as assets from '../../../../../../../assets';
import { useTranslation } from 'react-i18next';
import { ListOfExistingDataset } from '../../../../../../../components/rightPanel/subpanel/dataset/DatasetModal';
import Api from '../../../../../../../data/api/Api';
import { useSnackbar } from 'notistack';
import { AttachDataset } from '../AttachDataset';
import AttachNewDataset from '../attach-new-dataset/AttachNewDataset';
import { AppSkillId } from '../../../../../../../components/staticComponents/StaticHtmlGenerator';
import { useAppSelector } from '../../../../../../../reduxStore/redux-hooks';

function AttachExistingDataset({ attachDataset, setIsAgentCreateInProcess, attachDatasetTopK }: AttachDataset) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [datasetList, setDatasetList] = useState<ListOfExistingDataset[]>([]);
  const [selectedDataset, setSelectedDataset] = useState<ListOfExistingDataset | null>(null);
  const [isDatasetListLoading, setIsDatasetListLoading] = useState<boolean>(false);
  const { updateAgentDetails, isAgentToBeUpdated } = useAppSelector((state) => state.agents);

  useEffect(() => {
    fetchDatasets();
  }, []);

  // Function to fetch the list of existing datasets from the API
  const fetchDatasets = async () => {
    try {
      setIsDatasetListLoading(true);
      const listOfExistingDataset = (await Api.listDatasets(true, true, AppSkillId.DocCompletion))
        .data as ListOfExistingDataset[];
      setDatasetList(listOfExistingDataset);

      //update attach dataset details on edit agent.
      if (isAgentToBeUpdated) {
        prefillAttachDatasetDetails(listOfExistingDataset);
      }
      setIsDatasetListLoading(false);
    } catch (error) {
      enqueueSnackbar((error as Error).toString(), { variant: 'error' });
    }
  };

  //prefill the attach dataset details on edit agent.
  const prefillAttachDatasetDetails = (listOfExistingDataset: ListOfExistingDataset[]) => {
    const findAttachedDataset = listOfExistingDataset.find(
      (dataset) => dataset._id === updateAgentDetails.dataset_id
    ) as ListOfExistingDataset;
    attachDataset(findAttachedDataset._id);
    setSelectedDataset(findAttachedDataset);
  };

  // Function to handle the selection of a dataset from the dropdown
  const handleDatasetSelection = (
    event: React.SyntheticEvent<Element, Event>,
    datasetSelected: ListOfExistingDataset | null
  ) => {
    if (datasetSelected) {
      attachDataset(datasetSelected._id);
    }
    setSelectedDataset(datasetSelected);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', paddingTop: '20px', width: '615px' }}>
      {isDatasetListLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography sx={{ fontWeight: 700 }}>{t('fileUpload.existingDatasetLabel')}</Typography>
          <Autocomplete
            options={datasetList}
            getOptionLabel={(option) => option.name}
            value={selectedDataset}
            onChange={handleDatasetSelection}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('fileUpload.selectPlaceholder')}
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: selectedDataset && (
                    <img
                      src={assets.datasets_icon}
                      alt="Dataset Icon"
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        marginRight: '0.5rem',
                      }}
                    />
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                key={option._id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0.5rem',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <img
                    src={assets.datasets_icon}
                    style={{
                      width: '1.5rem',
                      height: '1.5rem',
                    }}
                    alt="Dataset Icon"
                  />
                  <Typography>{option.name}</Typography>
                </Box>
                <Typography sx={{ fontSize: '12px', fontWeight: 700, color: '#256AFF' }}>
                  {`${option.files.length} Files`}
                </Typography>
              </Box>
            )}
          />

          {/* If a dataset is selected and it has files, show the dataset content */}
          {selectedDataset && selectedDataset.files.length !== 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', marginTop: '1rem' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ color: '#636363' }}>{t('fileUpload.datasetContent')}</Typography>
                <Typography>{selectedDataset && selectedDataset.files.length}</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: '6.5rem',
                  overflowY: 'scroll',
                  gap: '.25rem',
                }}
              >
                {selectedDataset.files.map((file: File, index) => (
                  <Typography
                    key={index}
                    sx={{
                      padding: '.25rem .25rem',
                      background: '#F6F6F6B2',
                      borderRadius: '.25rem',
                    }}
                  >
                    {file.name}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}

          {selectedDataset && (
            <AttachNewDataset
              attachDataset={attachDataset}
              setIsAgentCreateInProcess={setIsAgentCreateInProcess}
              selectedExistingDataset={selectedDataset}
              attachDatasetTopK={attachDatasetTopK}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default AttachExistingDataset;
