import { Box, Button, List, ListItem, ListItemText, SvgIcon, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../reduxStore/redux-hooks';
import {
  confirmAgentUpdate,
  refreshAgentsList,
  resetActiveAgent,
  updateAgent,
} from '../../../../../reduxStore/slices/AgentsSlice';
import DeleteModal from '../../../../sharedComponents/DeleteModal';
import Api from '../../../../../data/api/Api';
import { lengthOfVisibleEditAgentOrTeamName, MyAgentsData } from '../myAgents/MyAgents';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { SectionDivider } from '../../../../staticComponents/StaticHtmlGenerator';
import ShareAgent from '../shareAgent/ShareAgent';
import { useSnackbar } from 'notistack';
import { AccountCircle, BoyOutlined } from '@mui/icons-material';
import User from '../../../../../helpers/User';
import ImageButton from '../../../../../components/sharedComponents/ImageButton';
import * as assets from '../../../../../assets';
import { AIAssetSharedUserDetails } from '../../teams/shareTeam/ShareTeam';
import { closeBasicPopoverManually, setOpenCreateAgentOrTeamPage } from '../../../../../reduxStore/slices/CommonSlice';

interface EditAgentProps {
  agent: MyAgentsData;
  isActiveAgent: boolean;
}

// Styles for the edit agent/team list items
export const editAgentOrTeamListStyles = {
  color: '#256AFF',
  transition: 'all 300ms ease-in-out',
  textDecoration: 'underline',
  padding: 0,
  cursor: 'pointer',
};

const EditAgent: React.FC<EditAgentProps> = ({ agent, isActiveAgent }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [showShareAgentDialog, setShowShareAgentDialog] = useState<boolean>(false);

  const [selectedAgentOwners, setSelectedAgentOwners] = useState<string[]>([]);
  const [selectedAgentSharedUsers, setSelectedAgentSharedUsers] = useState<AIAssetSharedUserDetails[]>([]);
  const [agentSharedUserToBeDeleted, setAgentSharedUserToBeDeleted] = useState<AIAssetSharedUserDetails>();
  const { enqueueSnackbar } = useSnackbar();
  const user = User.empty;

  useEffect(() => {
    getGraphData();
  }, []);

  useEffect(() => {
    // Fetch data and update user on component mount
    const fetchUserData = async () => {
      const fetchUser = await User.getInstance();
      Object.assign(user, fetchUser);
    };

    fetchUserData();
  }, []);

  const getGraphData = async () => {
    try {
      // Fetch user details using the acl.users from the original Agent interface
      const aclResponse = await Api.getUserDetailsByIds(agent.acl?.users || []);
      const agentSharedUserList = (agent.acl?.users || []).map((id) => ({
        id,
        name: aclResponse[id] || id,
      }));

      const ownersName = await Api.getUserDetailsByIds(agent.owners);
      const agentOwnersList = agent.owners.map((id) => ownersName[id] || id);

      setSelectedAgentOwners(agentOwnersList);
      setSelectedAgentSharedUsers(agentSharedUserList);
    } catch (error) {
      enqueueSnackbar(t('snackbar.alertMessage.errorGetGraphData'));
      console.log(error);
    }
  };

  // Function to unload the active agent
  const unloadActiveAgent = () => {
    // Show a user action content on the chat
    dispatch(showUserActionContentOnChat(t('rightPanel.agent.deactivateAgent', { activeAgentName: agent.name })));

    // Dispatch an action to set the active agent to an empty object
    dispatch(resetActiveAgent());
  };

  const shareAgent = () => {
    setShowShareAgentDialog(true);
  };

  const editAgent = () => {
    dispatch(setOpenCreateAgentOrTeamPage(true));
    dispatch(confirmAgentUpdate(true));
    dispatch(updateAgent(agent));
  };

  // List of options for editing the agent
  const editAgentOptionsList = [
    { label: t('rightPanel.agent.editAgent.edit'), sectionDivider: false, onClick: editAgent }, // Option to customize the agent
    {
      label: isActiveAgent ? t('rightPanel.agent.editAgent.unloadAgent') : t('rightPanel.favourite'), // Option to unload or favorite the agent
      sectionDivider: true,
      onClick: isActiveAgent ? unloadActiveAgent : () => {},
    },
    { label: t('rightPanel.agent.editAgent.manageShare'), sectionDivider: true, onClick: shareAgent }, // Option to manage the sharing of the agent
  ];

  // Function to confirm the deletion of the agent or agent shared user.
  const confirmDelete = async () => {
    agentSharedUserToBeDeleted ? await deleteAgentSharedUser(agentSharedUserToBeDeleted) : await deleteAgent();
    setIsDeleteModalOpen(false);
    dispatch(closeBasicPopoverManually(true));
  };

  const deleteAgentSharedUser = async (agentSharedUserToBeDeleted: AIAssetSharedUserDetails) => {
    try {
      await Api.deleteAgentSharedUser(agent._id, [agentSharedUserToBeDeleted.id]);
      dispatch(refreshAgentsList(true));
    } catch (error) {
      console.error('Error deleting agent shared user:', error);
    }
  };

  const deleteAgent = async () => {
    try {
      const deleteAgentResponse = await Api.deleteAgent(agent._id);

      // If the agent was deleted successfully
      if (deleteAgentResponse) {
        dispatch(showUserActionContentOnChat(t('rightPanel.agent.deleteAgent', { activeAgentName: agent.name })));
        if (isActiveAgent) {
          dispatch(resetActiveAgent());
        }
        dispatch(refreshAgentsList(true));
      }
    } catch (error) {
      console.error('Error deleting agent:', error);
    }
  };

  // Function to open the delete agent modal
  const confirmDeleteAgent = () => {
    setIsDeleteModalOpen(true);
  };

  // Function to open the delete agent shared user modal
  const confirmDeleteAgentSharedUser = (sharedUser: AIAssetSharedUserDetails) => {
    setAgentSharedUserToBeDeleted(sharedUser);
    setIsDeleteModalOpen(true);
  };

  // Function to close the delete agent modal
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const loadAgentOwners = () => {
    return selectedAgentOwners.length > 0 ? (
      selectedAgentOwners.map((agentOwner, index) => (
        <ListItem sx={{ padding: 0, justifyContent: 'space-between' }} key={index}>
          <ListItemText sx={{ marginRight: '50px' }}>{agentOwner}</ListItemText>
          <ListItemText sx={{ textAlign: 'right' }}>
            <span
              style={{
                fontSize: '12px',
                background: 'var(--global-color)',
                color: '#fff',
                padding: '2px 5px 3px',
                borderRadius: '4px',
              }}
            >
              {t('rightPanel.dataset.ownerBadge')}
            </span>
          </ListItemText>
        </ListItem>
      ))
    ) : (
      <Typography>{t('rightPanel.dataset.ownersNotFoudMessage')}</Typography>
    );
  };

  const loadAgentSharedUsers = () => {
    return (
      <>
        <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '10px' }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex' }}>
            <SvgIcon sx={{ marginRight: '10px' }} component={AccountCircle} />
            <Typography>{t('rightPanel.dataset.sharedHeading')}</Typography>
          </Box>
          <Typography sx={{ fontWeight: 600 }}>{selectedAgentSharedUsers.length}</Typography>
        </Box>
        <List sx={{ marginLeft: '25px' }}>
          {selectedAgentSharedUsers.length > 0 ? (
            selectedAgentSharedUsers.map((sharedUser, index) => (
              <ListItem sx={{ padding: 0, justifyContent: 'space-between' }} key={index}>
                <ListItemText>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', width: '200px', wordBreak: 'break-all' }}>
                      <SvgIcon sx={{ marginRight: '5px' }} component={BoyOutlined} />
                      <Typography sx={{ flex: 'none', fontWeight: '600', maxWidth: '170px' }}>
                        {sharedUser.name}
                      </Typography>
                    </Box>
                    {selectedAgentOwners.includes(user.fullName) && (
                      <ListItemText sx={{ flex: 'none' }}>
                        <ImageButton
                          srcFile={assets.removeUser}
                          handleClick={() => confirmDeleteAgentSharedUser(sharedUser)}
                        />
                      </ListItemText>
                    )}
                  </Box>
                </ListItemText>
              </ListItem>
            ))
          ) : (
            <Typography>{t('rightPanel.dataset.shareNotFoudMessage')}</Typography>
          )}
        </List>
      </>
    );
  };

  return (
    <>
      <Box sx={{ padding: '14px', width: '225px', borderRadius: '6px' }}>
        <Tooltip title={agent.name.length > lengthOfVisibleEditAgentOrTeamName ? agent.name : null} arrow>
          <Typography fontWeight={'700'} fontSize={'18px'} color={'#000'} overflow={'hidden'} textOverflow={'ellipsis'}>
            {agent.name}
          </Typography>
        </Tooltip>
        <List sx={{ padding: 0 }}>
          {editAgentOptionsList.map((item, index) => (
            <Box key={index}>
              {item.label === t('rightPanel.agent.editAgent.manageShare') && (
                <>
                  {loadAgentOwners()}
                  {loadAgentSharedUsers()}
                </>
              )}
              {item.label !== t('rightPanel.favourite') &&
                ((item.label !== t('rightPanel.agent.editAgent.edit') &&
                  item.label !== t('rightPanel.agent.editAgent.manageShare')) ||
                  selectedAgentOwners.includes(user.fullName)) && (
                  <ListItem key={index} sx={editAgentOrTeamListStyles}>
                    <ListItemText onClick={item.onClick}>{item.label}</ListItemText>
                  </ListItem>
                )}
              {item.sectionDivider && (
                <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '8px' }} />
              )}
            </Box>
          ))}
        </List>
        {selectedAgentOwners.includes(user.fullName) && (
          <Button
            sx={{
              color: '#BE266A',
              fontSize: '1rem',
              cursor: 'pointer',
              textTransform: 'none',
              textDecoration: 'underline',
              padding: '0',
            }}
            onClick={confirmDeleteAgent}
          >
            {t('rightPanel.agent.editAgent.deleteAgent')}
          </Button>
        )}

        {isDeleteModalOpen && (
          <DeleteModal
            openDeleteModal={isDeleteModalOpen}
            closeDeleteModal={closeDeleteModal}
            modalAPi={confirmDelete}
            dialogTitle={
              agentSharedUserToBeDeleted
                ? t('rightPanel.agent.editAgent.removeAgentSharedUser')
                : t('rightPanel.agent.editAgent.deleteAgent')
            }
            textContent={
              agentSharedUserToBeDeleted
                ? `${t('rightPanel.confirmRemove')} ${agentSharedUserToBeDeleted.name}?`
                : `${t('rightPanel.confirmDelete')} ${agent.name}?`
            }
            confirmButtonText={t('rightPanel.removeButton')}
          />
        )}
      </Box>
      {showShareAgentDialog && (
        <Box>
          <ShareAgent
            setShowShareAgentDialog={setShowShareAgentDialog}
            agent={agent}
            selectedAgentSharedUsers={selectedAgentSharedUsers}
            isNavigatedThroughManageShare={true}
          />
        </Box>
      )}
    </>
  );
};

export default EditAgent;
