import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ActiveNavItemContext } from '../../RightPanelContext';

function UserAvatar({ initials }: { initials: string }) {
  const { activeNavItem } = useContext(ActiveNavItemContext);
  const [userProfileActive, setuserProfileActive] = useState(false);

  useEffect(() => {
    setuserProfileActive(activeNavItem === 'developerKey' ? true : false);
  }, [activeNavItem]);

  return (
    <Box
      sx={{
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        display: 'flex',
        boxShadow: '0 2px 1px 0 #00000026',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        background: userProfileActive ? '#3C82F6' : '#fff',
        color: userProfileActive ? '#fff' : '#000',
      }}
      component={'div'}
    >
      {initials}
    </Box>
  );
}

export default UserAvatar;
