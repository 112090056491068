import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        paddingBottom: '1rem',
        borderTop: '1px solid rgb(229 231 235)',
        marginTop: '30px',
        padding: '15px',
        background: '#fff',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
      }}
    >
      <Typography>
        {t('footer.contactUs')}
        <a href={`mailto:${t('welcomeMessage.supportMail')}`} style={{ marginLeft: '5px' }}>
          {' '}
          {t('welcomeMessage.supportMail')}
        </a>{' '}
      </Typography>
    </Box>
  );
};

export default Footer;
