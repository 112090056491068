export enum SubPanelClasses {
  CreateAgentSubPanel = 'create-agent-sub-panel',
}

export enum ActiveSubPanelItems {
  Agent = 'agent',
  Teams = 'group',
  Dataset = 'dataset',
  ReleaseNotes = 'releaseNotes',
}
