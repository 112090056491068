import React, { useContext } from 'react';
import Logo from '../../components/Logo';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
import { saveInnovationCenterDetails } from '../../reduxStore/slices/FeaturedSlice';
import { ActiveNavItemContext } from '../../components/rightPanel/RightPanelContext';
import { useTranslation } from 'react-i18next';
import { ExternalId } from '../authConfig';
import { developerPortalLink, welcomePageNavigation } from '../../components/staticComponents/StaticHtmlGenerator';
import MenuIcon from '@mui/icons-material/Menu';
import { setRightPanelOpen } from '../../reduxStore/slices/CommonSlice';
import useResetActiveItems from '../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';
import { LogButtonEventToGA } from '../../components/sharedComponents/googleAnalytics/GoogleAnalytics';

interface HeaderProps {
  clearChatHistory: () => void;
}

const Header: React.FC<HeaderProps> = ({ clearChatHistory }) => {
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const externalId = ExternalId.tenantName;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isOpenRightPanel } = useAppSelector((state) => state.commonSlice);
  const resetActiveItems = useResetActiveItems();

  const handleWelcomePageRoutes = () => {
    clearChatHistory();
    dispatch(saveInnovationCenterDetails({ isActive: false }));
    resetActiveItems(t('welcomepage.Home'));
    setActiveNavItem(t('welcomepage.Home'));
  };

  const toggleRightPanel = () => {
    dispatch(setRightPanelOpen(!isOpenRightPanel));
  };

  const openDeveloperPortal = () => {
    LogButtonEventToGA(t('generateKey.developerPortal'));
    window.open(developerPortalLink, '_blank');
  };

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        paddingBottom: '1rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid rgb(229 231 235)',
        margin: '0 -15px 30px',
        padding: '15px',
        background: '#fff',
      }}
    >
      <Link to={welcomePageNavigation} onClick={handleWelcomePageRoutes}>
        <Logo externalId={externalId} />
      </Link>
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <Typography sx={{ fontWeight: '700', cursor: 'pointer', color: '#6F6C6C' }} onClick={openDeveloperPortal}>
          {t('generateKey.developerPortal')}
        </Typography>
        <Box
          sx={{
            marginRight: isOpenRightPanel ? '3.5rem' : 0,
            transition: 'all 200ms ease-in-out',
            cursor: 'pointer',
          }}
        >
          {<MenuIcon onClick={toggleRightPanel} />}
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
