import { Box, Drawer, useMediaQuery, Slide } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { NavItemButton } from './navItemButton/NavItemButton';
import { SubPanel } from '../subpanel/SubPanel';
import { useTranslation } from 'react-i18next';
import {
  chatPageNavigation,
  NAV_PANEL_WIDTH,
  NavItemImages,
  skillIdOfCustomApps,
  welcomePageNavigation,
} from '../../staticComponents/StaticHtmlGenerator';
import Logout from '../subpanel/logout/Logout';
import customAppsData from '../../rightPanel/subpanel/customApps/CustomApps.json';
import { ActiveNavItemContext } from '../RightPanelContext';
import UserAvatar from './userAvatar/UserAvatar';
import { UseTrackedNavigate } from '../../../components/sharedComponents/customHooks/useTrackedNavigate/UseTrackedNavigate';
import { LogTabEventToGA } from '../../../components/sharedComponents/googleAnalytics/GoogleAnalytics';
import { AppSkillId } from '../../staticComponents/StaticHtmlGenerator';
import Api from '../../../data/api/Api';
import { CustomAppSkill, ListOfExistingDataset } from '../subpanel/customApps/CustomApps';
import useResetActiveItems from '../../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';

export function RightNavPanel({ userFullName, initials }: { userFullName: string; initials: string }) {
  const portrait = useMediaQuery('(orientation: portrait)');
  const { t } = useTranslation();
  const navigate = UseTrackedNavigate();
  const { activeNavItem, setActiveNavItem } = useContext(ActiveNavItemContext);
  const [userHasAppAccess, setUserHasAppAccess] = useState(false);
  const resetActiveItems = useResetActiveItems();

  // Configuration for the nav items
  const navItemsConfig = [
    { name: t('welcomepage.Home'), imageUrl: NavItemImages.home, visible: true },
    { name: t('welcomepage.Chat'), imageUrl: NavItemImages.chat, visible: false },
    { name: t('welcomepage.Documents'), imageUrl: NavItemImages.dataset, visible: true },
    { name: t('welcomepage.Agent'), imageUrl: NavItemImages.agents, visible: true },
    { name: t('welcomepage.Teams'), imageUrl: NavItemImages.teams, visible: true },
    { name: t('welcomepage.Apps'), imageUrl: NavItemImages.apps, visible: userHasAppAccess },
    { name: t('welcomepage.History'), imageUrl: NavItemImages.history, visible: true },
    { name: t('welcomepage.Bell'), imageUrl: NavItemImages.bell, visible: false, badge: true },
  ];

  useEffect(() => {
    checkIfUserHasAppAccess();
  }, []);

  const checkIfUserHasAppAccess = async () => {
    const customAppSkillsList = await Api.fetchSkillsData();
    const appSkills = customAppSkillsList.data as CustomAppSkill[];
    if (appSkills.length > 3) {
      setUserHasAppAccess(true);
      return;
    }
    if (appSkills.length === 3) {
      const hasAccess = appSkills.every((customKey) => skillIdOfCustomApps.includes(customKey.skill_id as AppSkillId));
      if (!hasAccess) {
        setUserHasAppAccess(false);
        return;
      }
    }
    // Get custom apps details from a JSON file
    const customAppsDetails = customAppsData.apps_custom;
    const listOfDatasets = (await Api.listDatasets(true, true, AppSkillId.DocCompletion))
      .data as ListOfExistingDataset[];
    // Filter custom apps based on user's access to datasets
    const customAppUserAccess = customAppsDetails.some((customApp) =>
      listOfDatasets.some((dataset) => dataset._id === customApp.dataset_id)
    );
    setUserHasAppAccess(customAppUserAccess);
  };

  const clickOnNavButton = (name: string) => {
    setActiveNavItem(name);
    LogTabEventToGA(name);
    if (name === t('welcomepage.Home')) {
      navigate(welcomePageNavigation);
      resetActiveItems(name);
    }
    if (name === t('welcomepage.Chat')) {
      navigate(chatPageNavigation);
    }
  };

  const shouldRenderSubPanel = (activeNavItem: string | null) => {
    if (!activeNavItem) return false; // Handles null and empty string cases
    const excludedNavItems = [t('welcomepage.Home'), t('welcomepage.Chat')];
    return !excludedNavItems.includes(activeNavItem);
  };

  const loadNavItems = () => {
    return (
      <>
        {navItemsConfig.map(({ name, imageUrl, visible, badge }, index) => {
          if (visible) {
            return (
              <Box key={index}>
                <NavItemButton
                  key={index}
                  name={name}
                  imageUrl={imageUrl}
                  badge={badge}
                  isActive={activeNavItem === name}
                  clickOnNavButton={clickOnNavButton}
                />
              </Box>
            );
          }
          return null;
        })}
      </>
    );
  };

  return (
    <Box>
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <Drawer
          id={'right-nav-panel'}
          PaperProps={{
            sx: {
              backgroundColor: '#FBFAFA',
              border: '1px solid #F2F2F2',
              width: NAV_PANEL_WIDTH,
              height: '100%',
              marginTop: 0,
            },
          }}
          anchor={'right'}
          variant={'permanent'}
          open={true}
        >
          <Box
            sx={{
              padding: '14px 0 30px 0',
            }}
          >
            <Box onClick={() => clickOnNavButton('developerKey')} sx={{ cursor: 'pointer' }}>
              <UserAvatar initials={initials} />
            </Box>

            {loadNavItems()}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'flex-end',
              marginBottom: {
                xs: portrait ? '64px' : '0',
                md: '0',
              },
            }}
          >
            <Logout />
          </Box>
        </Drawer>
      </Slide>
      {shouldRenderSubPanel(activeNavItem) && <SubPanel userFullName={userFullName} />}
    </Box>
  );
}
