import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Api from '../../../../data/api/Api';
import {
  AppSkillId,
  chatPageNavigation,
  loggedInUserId,
} from '../../../../components/staticComponents/StaticHtmlGenerator';
import { Dataset, datasetSharedUser } from '../../../../components/rightPanel/subpanel/dataset/DatasetList';
import { useSnackbar } from 'notistack';
import { Box, Button, Card, CardContent, Input, Skeleton, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAppDispatch, useAppSelector } from 'reduxStore/redux-hooks';
import { setFiles, toggleModal, updateDatasetState } from 'reduxStore/slices/fileUploadSlice';
import { supportedFileTypes } from '../../../../components/rightPanel/subpanel/dataset/DatasetModal';
import { useNavigate } from 'react-router-dom';
import ShareDataset from '../../../../components/rightPanel/subpanel/dataset/ShareDataset';
import ImageButton from '../../../../components/sharedComponents/ImageButton';
import * as assets from '../../../../assets';
import {
  currentDataSet,
  ownerDataSet,
  selectedDataSet,
  setEditDataset,
  sharedDataSet,
} from 'reduxStore/slices/DataSetSlice';
import { showUserActionContentOnChat } from 'reduxStore/slices/NotifyUserActionContentSlice';
import { setRightPanelOpen, setOpenCreateAgentOrTeamPage } from '../../../../reduxStore/slices/CommonSlice';
import { ActiveNavItemContext } from '../../../../components/rightPanel/RightPanelContext';
import DeleteModal from '../../../../components/sharedComponents/DeleteModal';

const DatasetInfo = () => {
  const { t } = useTranslation();
  const filterDocumentTabs = [t('quickView.allDocuments')];
  const [initialFilterDocumentTab, setInitialFilterDocumentTab] = useState<string>(filterDocumentTabs[0]);
  const [loading, setLoading] = useState<boolean>(true);
  const [allDatasets, setAllDatasets] = useState<Dataset[]>([]);
  const [datasetOwners, setDatasetOwners] = useState<any>({});
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { fileUpload } = useAppSelector((state) => state.fileUpload);
  const navigate = useNavigate();
  const [isManageShareOpen, setIsManageShareOpen] = useState(false);
  const [currentSelectedDataset, setCurrentSelectedDataset] = useState<Dataset>();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const [selectedDatasetSharedUsers, setSelectedDatasetSharedUsers] = useState<datasetSharedUser[]>([]);
  const [isDatasetDeleteModalOpen, setIsDatasetDeleteModalOpen] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [refreshAllDatasetList, setRefreshAllDatasetList] = useState<boolean>(false);

  // UseEffect hook to fetch the list of datasets on component mount
  useEffect(() => {
    fetchDatasetsList();
  }, [refreshAllDatasetList]);

  // Function to fetch the list of datasets from the API
  const fetchDatasetsList = async () => {
    try {
      const allDatasetsListResponse = await Api.listDatasets(true, true, AppSkillId.DocCompletion);
      const datasets = allDatasetsListResponse.data as Dataset[];

      const filteredAndSortedDatasets = datasets
        .filter((dataset) => dataset.name)
        .sort((a, b) => a.name.localeCompare(b.name));

      // Take only the first 6 datasets for the quick view.
      setAllDatasets(filteredAndSortedDatasets.slice(0, 6));

      await fetchAndStoreDatasetOwnersData(filteredAndSortedDatasets.slice(0, 6));
      setLoading(false);
      setRefreshAllDatasetList(false);
    } catch (error) {
      enqueueSnackbar((error as Error).toString());
    }
  };
  const closeManageSharePopup = () => {
    setIsManageShareOpen(false);
    setRefreshAllDatasetList(true);
  };
  const openManageSharePopup = (dataset: Dataset) => {
    fetchAndStoreDatasetSharedUserDetails(dataset);
    setCurrentSelectedDataset(dataset);
    setIsManageShareOpen(true);
  };

  let dsSharedUser: datasetSharedUser[] = [];

  const fetchAndStoreDatasetSharedUserDetails = async (dataset: Dataset) => {
    try {
      // Fetch user details using the acl.users from the original Agent interface
      const aclResponse = await Api.getUserDetailsByIds(dataset.acl?.users || []);
      const datasetSharedUserList = (dataset.acl?.users || []).map((id) => ({
        id,
        name: aclResponse[id] || id,
      }));
      setSelectedDatasetSharedUsers(datasetSharedUserList);
      dsSharedUser = datasetSharedUserList;
    } catch (error) {
      enqueueSnackbar(t('snackbar.alertMessage.errorGetGraphData'));
      console.log(error);
    }
  };

  // Function to make dataset active
  const makeDatasetActive = async (dataset: Dataset) => {
    await fetchAndStoreDatasetSharedUserDetails(dataset);
    dispatch(
      selectedDataSet({
        activeDataSetName: dataset.name,
        activeDataSetId: dataset._id,
      })
    );
    dispatch(
      currentDataSet({
        currentDataSetName: dataset.name,
        currentDataSetId: dataset._id,
      })
    );
    dispatch(
      sharedDataSet({
        sharedDataList: { [dataset._id]: dsSharedUser },
      })
    );
    const ownersName = await Api.getUserDetailsByIds(dataset.owners);
    const ownerList = dataset.owners.map((id) => ownersName[id] || id);
    dispatch(
      ownerDataSet({
        ownersDataList: { [dataset._id]: ownerList },
      })
    );
    // dispatch(sharedDataSet({dataset._id,selectedDatasetSharedUsers}));
    dispatch(showUserActionContentOnChat(t('fileUpload.exisitingDatasetactiveMessage', { fileName: dataset.name })));
    navigate(chatPageNavigation);
  };

  const fetchAndStoreDatasetOwnersData = async (datasets: Dataset[]) => {
    // Fetch dataset owners for each dataset
    const datasetOwnerPromises = datasets.map(async (dataset) => {
      const datasetOwnerDetails = await Api.getUserDetailsByIds(dataset.owners);
      return datasetOwnerDetails;
    });

    // Wait for all dataset owner data to be fetched.
    const datasetOwnerData = await Promise.all(datasetOwnerPromises);

    // Transform the owner data into a single object where keys are owner IDs and values are owner names.
    const datasetOwnerIdAndNameMapping = datasetOwnerData.reduce((acc, datasetOwnerDetails) => {
      const datasetOwnerId = Object.keys(datasetOwnerDetails)[0];
      acc[datasetOwnerId] = datasetOwnerDetails[datasetOwnerId];
      return acc;
    }, {});

    setDatasetOwners(datasetOwnerIdAndNameMapping);
  };

  const documentCardActions = [
    {
      icon: assets.shareIcon,
      style: { padding: '0px 5px' },
      onClick: (dataset: Dataset, event: React.MouseEvent) => {
        event.stopPropagation();
        openManageSharePopup(dataset);
      },
    },
    {
      icon: assets.pencilIcon,
      style: { padding: '0px 5px' },
      onClick: (dataset: Dataset, event: React.MouseEvent) => {
        event.stopPropagation();
        openEditDatasetPage(dataset);
      },
    },
    {
      icon: assets.delete,
      style: { padding: '0px 5px' },
      onClick: (dataset: Dataset, event: React.MouseEvent) => {
        event.stopPropagation();
        openDeleteDatasetModal(dataset);
      },
    },
  ];

  const renderAllDocuments = () => {
    return allDatasets.map((dataset, index) => {
      return (
        <Card
          key={index}
          sx={{
            border: '1px solid #e5e7eb',
            backgroundColor: '#F9FAFB',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            overflow: 'visible',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={() => makeDatasetActive(dataset)}
        >
          <CardContent sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 700,
                  maxWidth: '20ch',
                }}
              >
                {dataset.name}
              </Typography>
              <Box>
                {documentCardActions.map((action, index) => {
                  return (
                    <ImageButton
                      key={index}
                      srcFile={action.icon}
                      disabled={dataset.owners[0] !== loggedInUserId()}
                      style={{
                        ...action.style,
                        opacity: dataset.owners[0] !== loggedInUserId() ? 0.5 : 1, // Set opacity for disabled state
                        pointerEvents: dataset.owners[0] !== loggedInUserId() ? 'none' : 'auto', // Ensure click events are disabled
                      }}
                      handleClick={(event) => {
                        if (action.onClick) {
                          action.onClick(dataset, event);
                        }
                      }}
                    />
                  );
                })}
              </Box>
            </Box>

            {[t('quickView.ownerLabel')].map((label, index) => (
              <Typography
                key={index}
                sx={{
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#4B5563',
                  lineHeight: '1.5rem',
                }}
              >
                {label}: {datasetOwners[dataset.owners[0]] || dataset.owners[0]}
              </Typography>
            ))}
          </CardContent>
        </Card>
      );
    });
  };

  const fileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files && files!.length === 0) return;
    dispatch(setFiles(Array.from(files as FileList)));
    e.target.value = '';
    dispatch(toggleModal());
  };

  const viewAllDocuments = () => {
    dispatch(setOpenCreateAgentOrTeamPage(false));
    dispatch(setRightPanelOpen(true));
    setActiveNavItem(t('welcomepage.Documents'));
  };

  const openDeleteDatasetModal = (dataset: Dataset) => {
    setCurrentSelectedDataset(dataset);
    setIsDatasetDeleteModalOpen(true);
  };

  // Function to confirm the deletion of the Dataset.
  const confirmDelete = async () => {
    await deleteDataset();
    setIsDatasetDeleteModalOpen(false);
  };

  const deleteDataset = async () => {
    try {
      if (currentSelectedDataset) {
        await Api.deleteDataset(currentSelectedDataset._id);
        enqueueSnackbar(t('quickView.deleteDataset', { datasetName: currentSelectedDataset.name }));
        setLoading(true);
        fetchDatasetsList();
      }
    } catch (error) {
      console.error('Error deleting Document:', error);
    }
  };

  // Function to close the delete Dataset modal
  const closeDatasetDeleteModal = () => {
    setIsDatasetDeleteModalOpen(false);
  };

  //Function to Edit Dataset
  const openEditDatasetPage = (dataset: Dataset) => {
    dispatch(
      setEditDataset({
        addFiles: true,
        datasetId: dataset._id,
      })
    );
    dispatch(
      updateDatasetState({
        selectedDatasetId: dataset._id,
      })
    );
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Open the file dialog
    }
  };

  return (
    <Box>
      <Box sx={{ display: { xs: 'block', sm: 'flex' }, justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" fontWeight={'700'}>
          {t('quickView.documents')}
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <label htmlFor="file-upload" style={{ display: 'none' }}>
            <Input
              ref={fileInputRef}
              id="file-upload"
              type="file"
              sx={{
                opacity: '0',
                zIndex: '99',
                left: fileUpload.uploadedFiles.length > 0 ? '10px' : '-10px',
                width: '1.86rem',
              }}
              inputProps={{
                multiple: true,
                accept: supportedFileTypes,
              }}
              onChange={fileInput}
            />
          </label>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              boxShadow: 'none',
              height: '2rem',
              '&:hover': { boxShadow: 'none' },
            }}
            variant="outlined"
            endIcon={<NavigateNextIcon />}
            size="small"
            onClick={viewAllDocuments}
          >
            {t('quickView.viewAll')}
          </Button>
          <label htmlFor="file-upload" className="custom-file-upload" style={{ position: 'relative', right: '2rem' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{ width: '10rem', height: '2rem', textTransform: 'none', left: '2rem', background: '#3C82F6' }}
              component="span"
            >
              {t('quickView.addDocument')}
            </Button>
          </label>
        </Box>
      </Box>

      <TabContext value={initialFilterDocumentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(_, newValue) => setInitialFilterDocumentTab(newValue)}
            aria-label="filter document tabs"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#2563EB !important',
              },
            }}
          >
            {filterDocumentTabs.map((tab) => (
              <Tab
                key={tab}
                label={tab}
                value={tab}
                sx={{
                  '&.Mui-selected': {
                    color: '#2563EB',
                    fontWeight: '700',
                    textTransform: 'none',
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        {filterDocumentTabs.map((tab) => (
          <TabPanel key={tab} value={tab} sx={{ paddingRight: '0px', paddingLeft: '0px' }}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', lg: '1fr 1fr 1fr' },
                gap: '30px',
              }}
            >
              {loading
                ? Array.from({ length: 6 }).map((_, index) => (
                    <Skeleton key={index} variant="rounded" width="100%" height={100} animation="wave" />
                  ))
                : renderAllDocuments()}
            </Box>
          </TabPanel>
        ))}
      </TabContext>
      {currentSelectedDataset && (
        <ShareDataset
          openShareDataset={isManageShareOpen}
          closeShareDataset={closeManageSharePopup}
          datasetId={currentSelectedDataset._id}
          sharedListDataset={selectedDatasetSharedUsers}
          isNavigatedThroughQuickView={true}
          datasetName={currentSelectedDataset.name}
        />
      )}

      {isDatasetDeleteModalOpen && (
        <DeleteModal
          openDeleteModal={isDatasetDeleteModalOpen}
          closeDeleteModal={closeDatasetDeleteModal}
          modalAPi={confirmDelete}
          dialogTitle={t('rightPanel.deleteDataset')}
          textContent={`${t('rightPanel.confirmDelete')} ${currentSelectedDataset && currentSelectedDataset.name}?`}
          confirmButtonText={t('rightPanel.removeButton')}
        />
      )}
    </Box>
  );
};

export default DatasetInfo;
