import { useAppSelector } from '../../../../reduxStore/redux-hooks';
import { Box, FormControl, FormHelperText, Input, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';
import {
  MAX_LENGTH_DATASET_NAME,
  MIN_LENGTH_DATASET_NAME,
} from '../../../../components/staticComponents/StaticHtmlGenerator';

interface FormValidationProps<T extends FieldValues> {
  control: Control<T>;
  errors: FieldErrors<T>;
}

interface NewDatasetProps {
  formValidation: FormValidationProps<{
    datasetName: string;
    datasetDescription: string;
  }>;
}

const NewDataset: React.FC<NewDatasetProps> = ({ formValidation }: NewDatasetProps) => {
  const { t } = useTranslation();
  const { fileUpload } = useAppSelector((state) => state.fileUpload);
  const { control, errors } = formValidation;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Controller
        control={control}
        name="datasetName"
        rules={{
          required: t('rightPanel.dataset.errorsValidationMessages.nameRequired'),
          validate: {
            noOnlySpaces: (value) => {
              const isValid = value.trim().length > MIN_LENGTH_DATASET_NAME;
              return isValid || t('rightPanel.dataset.errorsValidationMessages.nameRequired');
            },
            maxLength: (value) => {
              const isValid = value.trim().length <= MAX_LENGTH_DATASET_NAME;
              return isValid || t('rightPanel.dataset.errorsValidationMessages.nameCharacterLimit');
            },
          },
        }}
        render={({ field }) => {
          const truncatedDatasetName =
            field.value.length > MAX_LENGTH_DATASET_NAME
              ? `${field.value.slice(0, MAX_LENGTH_DATASET_NAME)}`
              : field.value;

          return (
            <FormControl fullWidth variant="standard" sx={{ marginBottom: '1.5rem' }}>
              <Typography fontSize={16} fontWeight={700}>
                {t('fileUpload.enterName')}
              </Typography>
              <Input
                {...field}
                fullWidth
                id="dataset-name"
                value={truncatedDatasetName}
                sx={{ padding: '0.2rem 0rem' }}
                error={!!errors.datasetName}
                inputProps={{ maxLength: MAX_LENGTH_DATASET_NAME + 1 }}
              />
              <Box>
                <FormHelperText
                  error
                  sx={{
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  {!!errors.datasetName && errors.datasetName.message}
                </FormHelperText>
              </Box>
            </FormControl>
          );
        }}
      ></Controller>

      <Box marginBottom={'1.5rem'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography fontSize={16} color={'#636363'}>
            {t('fileUpload.datasetContent')}
          </Typography>
          <Typography fontSize={16} color={'#636363'}>
            {fileUpload.uploadedFiles.length}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '6.5rem',
            overflowY: 'scroll',
            gap: '.25rem',
          }}
        >
          {fileUpload.uploadedFiles.map((file, index) => (
            <Typography key={index} padding={'.25rem .25rem'} bgcolor={'#F6F6F6B2'} borderRadius={'.25rem'}>
              {file.name}
            </Typography>
          ))}
        </Box>
      </Box>

      <Controller
        control={control}
        name="datasetDescription"
        rules={{
          maxLength: { value: 50, message: t('rightPanel.dataset.errorsValidationMessages.descriptionCharacterLimit') },
        }}
        render={({ field }) => (
          <FormControl fullWidth variant="standard" sx={{ marginBottom: '1.5rem' }}>
            <Typography fontSize={16} fontWeight={700}>
              {t('fileUpload.description')}
            </Typography>
            <Input
              {...field}
              fullWidth
              id="dataset-description"
              sx={{ padding: '0.2rem 0rem' }}
              error={!!errors.datasetDescription}
            />
            <Box>
              <FormHelperText
                error
                sx={{
                  position: 'absolute',
                  width: '100%',
                }}
              >
                {!!errors.datasetDescription && errors.datasetDescription.message}
              </FormHelperText>
            </Box>
          </FormControl>
        )}
      ></Controller>
    </Box>
  );
};

export default NewDataset;
