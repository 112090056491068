import { Box, Typography, Paper, IconButton } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { LogButtonEventToGA } from '../../../components/sharedComponents/googleAnalytics/GoogleAnalytics';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

// Define the CarouselItem interface before CarouselItemProps
interface CarouselItem {
  name: string;
  description: string;
  backGround: string;
}

function CarouselSlider() {
  const { t } = useTranslation();
  const items: CarouselItem[] = [
    {
      name: t('carouselCont.WhatsNew'),
      description: t('carouselCont.agentComplex'),
      backGround: 'black',
    },
    {
      name: 'Coming Soon',
      description: t('carouselCont.sharePoint'),
      backGround: 'black',
    },
    {
      name: 'Hangar7',
      description: t('carouselCont.hangerDesc'),
      backGround: 'black',
    },
  ];
  const handleNextClick = () => {
    LogButtonEventToGA(t('carouselCont.nextButton'));
  };
  const handlePrevClick = () => {
    LogButtonEventToGA(t('carouselCont.prevButton'));
  };
  const handleChangeClick = () => {
    LogButtonEventToGA(t('carouselCont.changeButton'));
  };

  return (
    <Box sx={{ marginBottom: '30px' }}>
      <Carousel
        autoPlay={false}
        navButtonsAlwaysVisible={false} // Make the nav buttons always visible
        NextIcon={
          <IconButton onClick={handleNextClick} style={{ color: '#fff' }}>
            <ArrowForward />
          </IconButton>
        } // Custom right arrow icon
        PrevIcon={
          <IconButton onClick={handlePrevClick} style={{ color: '#fff' }}>
            <ArrowBack />
          </IconButton>
        } // Custom left arrow icon
        onChange={() => {
          handleChangeClick();
        }} // Custom dots icon
      >
        {items.map((item, index) => (
          <CarouselItemComponent key={index} item={item} />
        ))}
      </Carousel>
    </Box>
  );
}

// Define CarouselItemComponent to render each carousel slide
interface CarouselItemProps {
  item: CarouselItem;
}

const CarouselItemComponent: React.FC<CarouselItemProps> = ({ item }) => {
  return (
    <Paper
      sx={{
        //height: '350px',
        background: item.backGround,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        padding: '30px 20px',
        //paddingRight: '20px',
      }}
    >
      <Typography
        component="div"
        className="textStyle"
        sx={{
          borderBottom: '1px solid #fff',
          marginBottom: '30px',
          width: '100%',
          textAlign: 'center',
          paddingBottom: '15px',
        }}
        variant="h5"
        gutterBottom
        fontWeight="700"
      >
        <ReactMarkdown>{item.name}</ReactMarkdown>
      </Typography>
      <Typography component="div" className="textStyle">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{item.description}</ReactMarkdown>
      </Typography>
    </Paper>
  );
};

export default CarouselSlider;
