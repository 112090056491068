import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { selectedDataSet } from '../../../../reduxStore/slices/DataSetSlice';
import { resetActiveAgent } from '../../../../reduxStore/slices/AgentsSlice';
import { useAppSelector } from '../../../../reduxStore/redux-hooks';
import { setClearChat } from '../../../../reduxStore/slices/CommonSlice';
import { resetActiveTeam } from '../../../../reduxStore/slices/TeamsSlice';
import { saveSelectedCustomApp } from '../../../../reduxStore/slices/CustomAppsSlice';
import { useTranslation } from 'react-i18next';
import { updateDatasetState } from '../../../../reduxStore/slices/fileUploadSlice';

const useResetActiveItems = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { activeDataSetName } = useAppSelector((state) => state.dataset.selectedDataSet);
  const { activeAgent } = useAppSelector((state) => state.agents);
  const { activeTeam } = useAppSelector((state) => state.teams);
  const { selectedCustomApp, isCustomAppSelected } = useAppSelector((state) => state.customApps.choosenCustomApp);

  const resetActiveItems = useCallback(
    async (activeNavItem: string | null) => {
      if (activeNavItem === t('welcomepage.Documents')) {
        dispatch(resetActiveAgent());
        dispatch(resetActiveTeam());
        dispatch(
          saveSelectedCustomApp({
            isCustomAppSelected: false,
            selectedCustomApp: '',
            selectedCustomAppSkill: '',
            welcomeMessage: '',
          })
        );
        dispatch(setClearChat(true));
      }

      if (activeNavItem === t('welcomepage.Agent')) {
        dispatch(
          selectedDataSet({
            activeDataSetId: '',
            activeDataSetName: '',
          })
        );

        dispatch(resetActiveTeam());

        dispatch(
          saveSelectedCustomApp({
            isCustomAppSelected: false,
            selectedCustomApp: '',
            selectedCustomAppSkill: '',
            welcomeMessage: '',
          })
        );
        dispatch(setClearChat(true));
      }

      if (activeNavItem === t('welcomepage.Teams')) {
        dispatch(
          selectedDataSet({
            activeDataSetId: '',
            activeDataSetName: '',
          })
        );
        dispatch(resetActiveAgent());

        dispatch(
          saveSelectedCustomApp({
            isCustomAppSelected: false,
            selectedCustomApp: '',
            selectedCustomAppSkill: '',
            welcomeMessage: '',
          })
        );
        dispatch(setClearChat(true));
      }

      if (activeNavItem === t('welcomepage.Apps')) {
        dispatch(resetActiveAgent());
        dispatch(resetActiveTeam());
        dispatch(
          selectedDataSet({
            activeDataSetId: '',
            activeDataSetName: '',
          })
        );
        dispatch(setClearChat(true));
      }

      if (activeNavItem === t('welcomepage.Home')) {
        dispatch(resetActiveAgent());
        dispatch(resetActiveTeam());
        dispatch(
          selectedDataSet({
            activeDataSetId: '',
            activeDataSetName: '',
          })
        );
        dispatch(
          saveSelectedCustomApp({
            isCustomAppSelected: false,
            selectedCustomApp: '',
            selectedCustomAppSkill: '',
            welcomeMessage: '',
          })
        );
        dispatch(
          updateDatasetState({
            makeActiveDataset: false,
          })
        );
        dispatch(setClearChat(true));
      }
    },
    [activeDataSetName, activeAgent, selectedCustomApp, activeTeam, isCustomAppSelected]
  );

  return resetActiveItems;
};

export default useResetActiveItems;
