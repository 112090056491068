import { Box, Typography, Tooltip, TextField } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect, useState } from 'react';
import Api from '../../../../data/api/Api';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { DeveloperKeyTooltip, loggedInUserId } from '../../../../components/staticComponents/StaticHtmlGenerator';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

interface Key {
  api_key: string;
  name: string;
  expiry_date: string;
}

interface UserDetails {
  displayName: string;
  givenName: string;
  mail: string | null;
}

const GenerateKey = () => {
  const [userDetails, setUserDetails] = useState<UserDetails>();
  const [developerKey, setDeveloperKey] = useState<Key>();
  const [devKeyName, setDevKeyName] = useState('');
  const { t } = useTranslation();

  const getUserDetails = async () => {
    const firstKey = loggedInUserId();
    if (firstKey) {
      const details = await Api.getCompleteUserDetailsByID(firstKey);
      setUserDetails(details as UserDetails);
    }
  };

  const createUserKey = async () => {
    if (!devKeyName) {
      enqueueSnackbar(t('generateKey.giveValidName'), { variant: 'error' });
      return;
    }
    if (developerKey?.api_key) {
      enqueueSnackbar(t('generateKey.keyCreatedMessage'), { variant: 'error' }); // not needed now, keeping this in case multiple key invocation comes into play.
      return;
    }
    if (devKeyName.length <= 3 || devKeyName.length >= 50) {
      enqueueSnackbar(t('generateKey.atLeast4Char'), { variant: 'error' });
      return;
    }
    const response = await Api.createApiKey(devKeyName);
    if (response && response.data) {
      setDeveloperKey({
        api_key: response.data.api_key || '',
        name: devKeyName || '',
        expiry_date: response.data.expiry_date,
      });
      enqueueSnackbar(t('generateKey.keyCreatedSuccessMessage'), { variant: 'success' }); // not needed now, keeping this in case multiple key invocation comes into play.
    }
  };

  const revokeUserKey = async () => {
    if (developerKey && developerKey.api_key) {
      await Api.deleteApiKey(developerKey.api_key);
      enqueueSnackbar(t('generateKey.revokeKeySuccessMessage'), { variant: 'success' });
      setDeveloperKey({ api_key: '', name: '', expiry_date: '' });
      setDevKeyName('');
    }
  };

  const handleCopyKey = () => {
    if (developerKey && developerKey.api_key) {
      try {
        navigator.clipboard.writeText(developerKey.api_key);
        enqueueSnackbar(t('generateKey.keyCopied'), { variant: 'success' });
      } catch {
        enqueueSnackbar(t('generateKey.keyCopiedError'), { variant: 'error' });
      }
    }
  };

  const getUserKeyOnInitialLoad = async () => {
    const response = await Api.getUserKey();
    if (response) {
      setDeveloperKey({
        api_key: response.api_key || '',
        name: response.name || '',
        expiry_date: response.expiry_date || '',
      });
      setDevKeyName(response.name || '');
    }
  };
  const BorderlessTextField = styled(TextField)`
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  `;

  useEffect(() => {
    getUserDetails();
    getUserKeyOnInitialLoad();
  }, []);

  return (
    <>
      <Box>
        <Box sx={{ marginTop: '-19px' }}>
          {userDetails ? (
            <>
              <Typography>{userDetails.displayName}</Typography>
              <Typography sx={{ fontSize: 'small' }}>{userDetails.mail}</Typography>
            </>
          ) : (
            <Typography>{t('generateKey.Loading')}</Typography>
          )}
        </Box>
        <Box sx={{ marginTop: '2rem', marginBottom: '0.5rem', color: '#6F6C6C' }}>
          <Typography sx={{ fontWeight: '700', display: 'inline', fontSize: '20px' }}>
            {t('generateKey.developerKey')}
          </Typography>

          {/* Info Icon with Tooltip */}
          <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{DeveloperKeyTooltip}</div>} arrow placement="top">
            <InfoIcon sx={{ fontSize: '16px', cursor: 'pointer', marginLeft: '9px' }} />
          </Tooltip>
        </Box>
        <Typography sx={{ paddingLeft: '10px', fontWeight: '800', color: '#6F6C6C' }}>
          {t('generateKey.tokenName')}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Left Section */}
          <Box>
            {developerKey && developerKey.name ? (
              <BorderlessTextField variant="outlined" value={developerKey.name} type="text" sx={{ width: '220px' }} />
            ) : (
              <TextField
                value={devKeyName}
                onChange={(event) => setDevKeyName(event.target.value)}
                placeholder={t('generateKey.keyNamePlaceholder')}
                variant="outlined"
                size="small"
                sx={{
                  width: '200px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                }}
              />
            )}
          </Box>

          {/* Right Section */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              width: '30px',
              height: '30px',
              marginRight: '10px',
              cursor: 'pointer',
            }}
          >
            {developerKey && developerKey.name ? (
              <Tooltip title={t('generateKey.revoke')} arrow placement="top">
                <DeleteForeverIcon
                  sx={{
                    color: 'grey',
                    height: '30px',
                    '&:hover': {
                      color: '#3C82F6',
                    },
                  }}
                  onClick={revokeUserKey}
                />
              </Tooltip>
            ) : (
              <Tooltip title={t('generateKey.generate')} arrow>
                <ArrowForwardOutlinedIcon
                  sx={{
                    color: 'grey',
                    '&:hover': {
                      color: '#3C82F6',
                    },
                  }}
                  onClick={createUserKey}
                />
              </Tooltip>
            )}
          </Box>
        </Box>

        {developerKey && developerKey.api_key && (
          <>
            <Typography sx={{ paddingLeft: '10px', fontWeight: '800', color: '#6F6C6C' }}>
              {t('generateKey.tokenKey')}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ alignItems: 'center' }}>
                <BorderlessTextField
                  variant="outlined"
                  value={developerKey.api_key}
                  type="password"
                  sx={{ width: '220px' }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '8px',
                  width: '30px',
                  height: '30px',
                  marginRight: '10px',
                  cursor: 'pointer',
                  marginTop: '15px',
                }}
              >
                <Tooltip title="Copy Key" arrow>
                  <ContentCopyIcon
                    onClick={handleCopyKey}
                    sx={{
                      color: 'grey',
                      height: '20px',
                      '&:hover': {
                        color: '#3C82F6',
                      },
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </>
        )}
        {developerKey && developerKey.expiry_date && (
          <Box>
            <Typography sx={{ color: '#6F6C6C', paddingLeft: '12px' }}>
              <strong> {t('generateKey.validTill')}</strong> {developerKey?.expiry_date}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default GenerateKey;
