import BasicPopover from '../../../../sharedComponents/BasicPopover';
import ImageButton from '../../../../sharedComponents/ImageButton';
import {
  Box,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Api from '../../../../../data/api/Api';
import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import EditAgent from '../editAgent/EditAgent';
import * as assets from '../../../../../assets';
import { useTranslation } from 'react-i18next';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { buttonStyle } from '../../dataset/Dataset';
import { chatPageNavigation, hiddenMyAgentsList } from '../../../../../components/staticComponents/StaticHtmlGenerator';
import { useNavigate } from 'react-router-dom';
import UsePageNavigation from '../../../../sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import { activeAgentMethod, refreshAgentsList } from '../../../../../reduxStore/slices/AgentsSlice';
import useResetActiveItems from '../../../../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';
import { ActiveNavItemContext } from '../../../../../components/rightPanel/RightPanelContext';

export interface MyAgentsData {
  name: string;
  _id: string;
  description: string;
  model_name: string;
  temperature: number;
  max_output_tokens: number | null;
  instructions: string;
  tools: string[] | null;
  dataset_id: string | null;
  top_k?: number;
  type: string;
  owners: string[];
  acl?: {
    users: string[];
  };
  allow_all_access?: boolean;
}

export interface MyAgentsDataResponse {
  data: MyAgentsData[];
  response: Response;
}

export const lengthOfVisibleAgentOrTeamName = 25;
export const lengthOfVisibleEditAgentOrTeamName = 20;

const MyAgents = () => {
  const [myAgents, setMyAgents] = useState<MyAgentsData[]>([]);
  const [filteredMyAgentsList, setFilteredMyAgentsList] = useState<MyAgentsData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { activeAgent, refetchAgentsList } = useAppSelector((state) => state.agents);
  const { activeNavItem } = useContext(ActiveNavItemContext);

  const { t } = useTranslation();
  const { isWelcomePage } = UsePageNavigation();
  const navigate = useNavigate();
  const resetActiveItems = useResetActiveItems();

  // UseEffect hook to fetch the list of agents on component mount
  useEffect(() => {
    fetchMyAgentsList();
  }, []);

  // Function to refresh the list of agents.
  useEffect(() => {
    if (refetchAgentsList) {
      fetchMyAgentsList();
      dispatch(refreshAgentsList(false));
    }
  }, [refetchAgentsList]);

  // Function to fetch the list of agents from the API
  const fetchMyAgentsList = () => {
    Api.getMyAgentsList()
      .then(async (response) => {
        setLoading(false);
        const myAgentsListResp = response as MyAgentsDataResponse;
        myAgentsListResp.data = myAgentsListResp.data.filter(
          (agent) => !hiddenMyAgentsList.includes(agent.name.toLowerCase())
        );
        const sortedAgents = myAgentsListResp.data.sort((a, b) => a.name.localeCompare(b.name));
        setMyAgents(sortedAgents);
        setFilteredMyAgentsList(sortedAgents);

        //if the agent is active and if its data gets updated then make sure
        //to update the active agent data too.
        if (activeAgent._id) {
          dispatch(activeAgentMethod(sortedAgents.find((agent) => agent._id === activeAgent._id) as MyAgentsData));
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error in fetching my agents list:', error);
      });
  };

  // Function to set the active agent
  const makeAgentActive = async (agentData: MyAgentsData) => {
    await resetActiveItems(activeNavItem);
    dispatch(activeAgentMethod(agentData));
    dispatch(showUserActionContentOnChat(t('rightPanel.agent.activateAgent', { activeAgentName: agentData.name })));

    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };

  // Function to filter the list of agents based on the search query
  const filterMyAgentsList = (agentName: string) => {
    setFilteredMyAgentsList(myAgents.filter((agent) => agent.name.toLowerCase().includes(agentName.toLowerCase())));
  };

  const renderEditAgentPopover = (agent: MyAgentsData) => {
    return (
      <BasicPopover
        srcComponent={
          <Box className="hover-showDotsButton">
            <ImageButton srcFile={assets.dots} style={buttonStyle} />
          </Box>
        }
        contentComponent={<EditAgent agent={agent} isActiveAgent={false} />}
      />
    );
  };

  return (
    <Box>
      <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
        {t('rightPanel.agent.myAgents')}
      </Typography>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Box>
          {!myAgents.length ? (
            <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
              {t('rightPanel.agent.noCustomAgents')}
            </Typography>
          ) : (
            <>
              <TextField
                label={t('rightPanel.agent.searchAgentName')}
                variant="standard"
                id="searchAgent"
                onChange={(event) => filterMyAgentsList(event.target.value)}
                size="small"
                fullWidth
              />
              <List sx={{ maxHeight: '35rem', overflow: 'auto' }}>
                {filteredMyAgentsList.map((agent) => (
                  <Box key={agent._id} className="agents-list">
                    <ListItemButton
                      disabled={activeAgent._id === agent._id}
                      sx={{
                        padding: '5px 10px',
                        // paddingLeft: '0px',
                      }}
                    >
                      {/* <ListItemAvatar sx={{ minWidth: '50px' }} onClick={() => makeAgentActive(agent)}>
                        <Avatar alt="default agent" src={assets.ai360Agent} />
                      </ListItemAvatar> */}
                      <Tooltip title={agent.name.length > lengthOfVisibleAgentOrTeamName ? agent.name : null} arrow>
                        <ListItemText
                          primary={agent.name}
                          sx={{
                            padding: '8px 0px',
                            margin: '0px',
                            '& .MuiListItemText-primary': {
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            },
                          }}
                          onClick={() => makeAgentActive(agent)}
                        />
                      </Tooltip>
                      {activeAgent._id !== agent._id && (
                        <span className="buttonShowOnHover">{renderEditAgentPopover(agent)}</span>
                      )}
                    </ListItemButton>
                  </Box>
                ))}
                {filteredMyAgentsList.length === 0 && <Typography color="red">{t('rightPanel.noResult')}</Typography>}
              </List>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MyAgents;
