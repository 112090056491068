import React, { useEffect, useMemo, useRef, useState } from 'react';
import { List, ListItem, ListItemButton, ListItemText, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../reduxStore/redux-hooks';

interface SearchListItem {
  _id: string;
  name: string;
  owners: string[];
  users: string[];
}

interface SearchListProps {
  onSelectItem: (listItems: SearchListItem) => void;
  renderPopover?: (listItems: SearchListItem) => React.ReactNode;
  items: SearchListItem[];
  label: string;
  showDetailsOnHover: boolean;
}

const SearchList: React.FC<SearchListProps> = ({ label, items, onSelectItem, showDetailsOnHover, renderPopover }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const listRef = useRef<HTMLUListElement>(null);
  const { isCustomAppSelected } = useAppSelector((state) => state.customApps.choosenCustomApp);
  const { activeDataSetId } = useAppSelector((state) => state.dataset.selectedDataSet);

  const filteredResult = useMemo(
    () => items.filter((dataset) => dataset.name.toLowerCase().includes(searchQuery.toLowerCase())),
    [items, searchQuery]
  );

  useEffect(() => {
    const listElement = listRef.current;
    if (listElement) {
      setIsVisible(listElement.scrollHeight > listElement.clientHeight);
    }
  }, [items]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      {isVisible && (
        <TextField
          label={label}
          variant="standard"
          value={searchQuery}
          onChange={handleInputChange}
          size="small"
          fullWidth
        />
      )}

      <List
        ref={listRef}
        className="dataList_ul"
        sx={{ overflowY: 'auto', maxHeight: isCustomAppSelected ? '15rem' : '25rem', padding: 0 }}
      >
        {filteredResult?.map((listItem) => (
          <ListItemButton
            key={listItem._id}
            disabled={activeDataSetId === listItem._id}
            sx={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <ListItem
              key={listItem._id}
              sx={{
                color: '#256AFF',
                transition: 'all 300ms ease-in-out',
                textDecoration: 'underline',
                padding: 0,
                cursor: 'pointer',
                wordWrap: 'break-word',
              }}
            >
              <ListItemText primary={listItem.name} onClick={() => onSelectItem(listItem)} />
              <span className="buttonShowOnHover">
                {showDetailsOnHover && renderPopover ? renderPopover(listItem) : null}
              </span>
            </ListItem>
          </ListItemButton>
        ))}
      </List>
      {filteredResult?.length === 0 && <Typography color="red">{t('rightPanel.noResult')}</Typography>}
    </>
  );
};

export default SearchList;
