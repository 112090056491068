import { useAppDispatch, useAppSelector } from '../../../../../reduxStore/redux-hooks';
import { ActiveNavItemContext } from '../../../../../components/rightPanel/RightPanelContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import Api from '../../../../../data/api/Api';
import { teamActivationMethod } from '../../../../../reduxStore/slices/TeamsSlice';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  InputLabel,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import * as assets from '../../../../../assets';
import { LoadingButton } from '@mui/lab';
import { MyAgentsData, MyAgentsDataResponse } from '../../agents/myAgents/MyAgents';
import { hiddenMyAgentsList } from '../../../../../components/staticComponents/StaticHtmlGenerator';
import ShareTeam from '../shareTeam/ShareTeam';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { ValidationError, validationErrorMessage } from '../../../../../components/staticComponents/staticUtiles';
import { resetAgentActivationProcess } from '../../../../../reduxStore/slices/AgentsSlice';
import { setOpenCreateAgentOrTeamPage } from '../../../../../reduxStore/slices/CommonSlice';

interface CreateTeamData {
  name: string;
  _id: string;
  description: string;
  assistant_ids: string[];
  owners: string[];
  acl?: {
    users: string[];
  };
}

function CreateTeam() {
  const { t } = useTranslation();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const dispatch = useAppDispatch();
  const [isTeamCreateInProcess, setIsTeamCreateInProcess] = useState<boolean>(false);
  const [agents, setAgents] = useState<MyAgentsData[]>([]);
  const [selectedAgents, setSelectedAgents] = useState<MyAgentsData[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [showShareTeamDialog, setShowShareTeamDialog] = useState<boolean>(false);
  const { activationTeam, ShowTeamActivationDialogOnChat } = useAppSelector(
    (state) => state.teams.teamActivationProcess
  );
  const { updateTeamDetails, isTeamToBeUpdated } = useAppSelector((state) => state.teams);

  //register create team form fields with default values to useForm.
  //useForm is a custom hook for managing forms with ease.
  const { watch, control, getValues, setValue } = useForm({
    defaultValues: {
      name: '',
      description: '',
      assistant_ids: [''],
      //this instructions field is not mandatory and can be removed completely once
      //this gets removed from API in the BE.
      instructions: 'Not Applicable',
    },
  });

  // UseEffect hook to fetch the list of agents on component mount
  useEffect(() => {
    fetchMyAgentsList();
    //this instructions field is not mandatory and can be removed completely once
    //this gets removed from API in the BE.
    setValue('instructions', 'Not Applicable');
  }, []);

  const updateTeamFields = (sortedAgents: MyAgentsData[]) => {
    setValue('name', updateTeamDetails.name);
    setValue('description', updateTeamDetails.description);
    setValue('assistant_ids', updateTeamDetails.assistant_ids);
    setSelectedAgents(sortedAgents.filter((agent) => updateTeamDetails.assistant_ids.includes(agent._id)));
  };

  // Function to fetch the list of agents from the API
  const fetchMyAgentsList = () => {
    Api.getMyAgentsList()
      .then((response) => {
        const myAgentsListResp = response as MyAgentsDataResponse;
        myAgentsListResp.data = myAgentsListResp.data
          .filter((agent) => !hiddenMyAgentsList.includes(agent.name.toLowerCase()))
          .sort((a, b) => a.name.localeCompare(b.name));
        setAgents(myAgentsListResp.data);
        if (isTeamToBeUpdated) {
          updateTeamFields(myAgentsListResp.data);
        }
      })
      .catch((error) => {
        console.error('Error in fetching my agents list:', error);
      });
  };

  const handleAgentSelection = (event: React.SyntheticEvent, selectedAgents: MyAgentsData[]) => {
    setSelectedAgents(selectedAgents);
    setValue(
      'assistant_ids',
      selectedAgents.map((agent) => agent._id)
    );
  };

  const deleteSelectedAgent = (removeSelectedAgent: MyAgentsData) => {
    const updatedSelectedAgents = selectedAgents.filter((agent) => agent._id !== removeSelectedAgent._id);
    setSelectedAgents(updatedSelectedAgents);
    setValue(
      'assistant_ids',
      updatedSelectedAgents.map((agent) => agent._id)
    );
  };

  const createTeamFormSubmit = async () => {
    const createTeamFormData = getValues();
    setIsTeamCreateInProcess(true);
    try {
      if (ShowTeamActivationDialogOnChat) {
        dispatch(
          teamActivationMethod({
            ShowTeamActivationDialogOnChat: false,
            activationTeam: activationTeam,
          })
        );
      }
      const createOrUpdateTeam = isTeamToBeUpdated
        ? Api.updateTeam(createTeamFormData, updateTeamDetails)
        : Api.createTeam(createTeamFormData);
      await createOrUpdateTeam.then((createTeamResponse) => {
        if (createTeamResponse.data) {
          dispatch(resetAgentActivationProcess());
          const createTeamData = createTeamResponse.data as CreateTeamData;
          //once team is successfully created hold these team details to show information on the chat for the user to perform some action.
          if (!isTeamToBeUpdated) {
            dispatch(
              teamActivationMethod({
                ShowTeamActivationDialogOnChat: true,
                activationTeam: createTeamData,
              })
            );
            setShowShareTeamDialog(true);
          } else {
            setActiveNavItem(null);
            dispatch(
              showUserActionContentOnChat(
                t('rightPanel.team.createTeam.updateTeamSuccess', {
                  updatingTeamName: createTeamData.name ? createTeamData.name : updateTeamDetails.name,
                })
              )
            );
          }
        } else if (createTeamResponse.error) {
          enqueueSnackbar(validationErrorMessage(createTeamResponse.error as ValidationError).toString(), {
            variant: 'error',
          });
        }
      });
    } catch (error) {
      enqueueSnackbar((error as Error).toString(), { variant: 'error' });
    }
    setIsTeamCreateInProcess(false);
    dispatch(setOpenCreateAgentOrTeamPage(false));
  };

  const closeSubPanel = () => {
    setActiveNavItem(null);
    setShowShareTeamDialog(false);
    dispatch(setOpenCreateAgentOrTeamPage(false));
  };

  return (
    <Box padding={'0% 5% 0% 20%'}>
      <Box padding={'20px 0px'}>
        <Typography sx={{ color: '#000', fontWeight: '700', fontSize: '22px', textAlign: 'center' }}>
          {isTeamToBeUpdated
            ? t('rightPanel.team.createTeam.updateTeam')
            : t('rightPanel.team.createTeam.createNewTeam')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '40px', marginBottom: '40px' }}>
        <Box sx={{ marginLeft: { xs: '0', sm: '0', lg: '-106px' }, display: { xs: 'none', sm: 'none', lg: 'block' } }}>
          <img width={66} height={66} src={assets.teamsIconEdit} alt="team edit icon" />
        </Box>
        <Box flexGrow={1}>
          <InputLabel sx={{ color: '#0B0C0C', fontWeight: '700', fontSize: '16px' }}>
            {t('rightPanel.team.createTeam.teamName') + '*'}
          </InputLabel>
          {/* The Controller from react-hook-form is used into our form,
          providing it with the necessary props and state to manage its 
          value, validation, and error handling, */}
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="standard"
                fullWidth
                placeholder={t('rightPanel.agent.createAgent.placeHolderForAgentName')}
              />
            )}
          />
        </Box>
      </Box>
      <Box marginBottom={'40px'}>
        <InputLabel sx={{ color: '#0B0C0C', fontWeight: '700', fontSize: '16px' }}>
          {t('rightPanel.agent.createAgent.description')}
        </InputLabel>
        <Controller
          control={control}
          name="description"
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="standard"
              fullWidth
              placeholder={t('rightPanel.team.createTeam.placeHolderForDescription')}
            />
          )}
        />
      </Box>
      <Box marginBottom={'40px'} display={'flex'} gap={'50px'}>
        <Box width={'100%'}>
          <InputLabel className="mui-form-label">{t('rightPanel.team.createTeam.selectAgents') + '*'}</InputLabel>
          <Autocomplete
            multiple
            id="agents"
            options={agents}
            value={selectedAgents}
            onChange={handleAgentSelection}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <ListItem
                  key={key}
                  {...optionProps}
                  sx={{
                    '&.MuiAutocomplete-option[aria-selected="true"]': {
                      backgroundColor: '#FFFFFF !important',
                    },
                  }}
                >
                  <ListItemText
                    sx={{
                      marginLeft: '30px',
                      padding: '5px',
                      backgroundColor: selected ? '#F7FFF3' : 'transparent',
                    }}
                    primary={option.name}
                  />
                  <Box
                    sx={{
                      marginRight: '30px',
                      paddingTop: '5px',
                      paddingRight: '5px',
                      backgroundColor: selected ? '#F7FFF3' : 'transparent',
                    }}
                  >
                    {selected ? (
                      <img src={assets.selectCircleIcon} alt="selected icon" />
                    ) : (
                      <img src={assets.plusCircleIcon} alt="select icon" />
                    )}
                  </Box>
                </ListItem>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ padding: '8px 0px' }}
                variant="standard"
                placeholder={t('rightPanel.team.createTeam.selectAgentsPlaceHolder')}
              />
            )}
            renderTags={() => null}
          />
          <Box fontSize={'14px'} color={'#3C82F6'} paddingBottom={'10px'}>
            {t('rightPanel.team.createTeam.createTeamHelperText')}
          </Box>
          {selectedAgents.length > 0 && (
            <Typography sx={{ color: '#7E8286', fontSize: '12px', fontWeight: '500' }}>
              {t('rightPanel.team.createTeam.addedAgents')}
            </Typography>
          )}
          {selectedAgents.map((agent: MyAgentsData) => (
            <Chip
              sx={{
                margin: '5px 0px',
                marginRight: '10px',
                padding: '4px 10px',
                borderRadius: '4px',
                backgroundColor: '#3F3F3F',
                color: '#F2F2F2',
                position: 'relative',
              }}
              key={agent._id}
              label={agent.name}
              onDelete={() => deleteSelectedAgent(agent)}
              deleteIcon={
                <Box sx={{ position: 'absolute', right: '-10px', top: '-10px' }}>
                  <img src={assets.cancelIcon} alt="cancel icon" />
                </Box>
              }
            />
          ))}
        </Box>
      </Box>

      <Box sx={{ float: 'right', gap: '34px', display: 'flex', paddingBottom: '50px' }}>
        <Button variant="text" sx={{ textTransform: 'none', textDecoration: 'underline' }} onClick={closeSubPanel}>
          {t('rightPanel.agent.createAgent.cancel')}
        </Button>
        <LoadingButton
          type="button"
          variant="contained"
          sx={{
            textTransform: 'none',
            backgroundColor: '#3C82F6',
          }}
          disabled={!watch('name') || selectedAgents.length < 2}
          loading={isTeamCreateInProcess}
          onClick={createTeamFormSubmit}
        >
          {isTeamToBeUpdated ? t('rightPanel.team.createTeam.updateTeam') : t('rightPanel.team.createTeam.teamCreate')}
        </LoadingButton>
      </Box>
      {showShareTeamDialog && (
        <Box>
          <ShareTeam setShowShareTeamDialog={setShowShareTeamDialog} team={activationTeam} />
        </Box>
      )}
    </Box>
  );
}

export default CreateTeam;
