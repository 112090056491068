import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../reduxStore/redux-hooks';
import React, { useEffect, useState, RefObject } from 'react';
import ResponseContent from './ResponseContent';
import DialogBox from './DialogBox';
import Api from '../../data/api/Api';
import { LlmModel } from '../../data/models/LlmModel';

interface ResponseContentProps {
  appMessage: string;
  isLoading: boolean;
  isStreamComplete: boolean;
}

const LoadDynamicMessageOnChat = (responseBoxRef: RefObject<HTMLDivElement>) => {
  const { t } = useTranslation();

  const { ShowAgentActivationDialogOnChat } = useAppSelector((state) => state.agents.agentActivationProcess);
  const { datasetModals, fileSelection, dataset } = useAppSelector((state) => state.fileUpload);
  const [responseContentProps, setResponseContentProps] = useState<ResponseContentProps | null>(null);
  const { selectedModelHistory } = useAppSelector((state) => state.history.historyState);
  const [llmModels, setLlmModels] = useState([LlmModel.defaultModal]);
  const [selectedModel, setSelectedModel] = useState(LlmModel.defaultModal);
  const { ShowTeamActivationDialogOnChat } = useAppSelector((state) => state.teams.teamActivationProcess);
  const { isCustomAppSelected } = useAppSelector((state) => state.customApps.choosenCustomApp);

  useEffect(() => {
    if (responseBoxRef) {
      if (responseBoxRef.current) {
        responseBoxRef.current.scrollTop = responseBoxRef.current.scrollHeight;
      }
    }
  }, [responseContentProps, ShowAgentActivationDialogOnChat, ShowTeamActivationDialogOnChat]);

  useEffect(() => {
    //this function handles dataset preparations
    if (fileSelection.responseMessage || dataset.makeActiveDataset || !datasetModals.successResponseModal) {
      handleDatasetPreparation();
    }
  }, [fileSelection.responseMessage, dataset.makeActiveDataset, datasetModals.successResponseModal]);

  useEffect(() => {
    // loads the list of LLM Models
    Api.listLlmModels().then((response) => {
      setLlmModels(response);
    });
  }, []);

  useEffect(() => {
    const modelFound = llmModels.find((model) => model.id === selectedModelHistory);
    setSelectedModel(modelFound ? modelFound : LlmModel.defaultModal);
  }, [selectedModelHistory]);

  const handleDatasetPreparation = () => {
    const dataSetPreparationResult: string | null =
      fileSelection.responseMessage && !isCustomAppSelected
        ? t('fileUpload.dataProccesedMessage')
        : // The data processing message will be showned while custom app selected and creating new dataset
          fileSelection.responseMessage && isCustomAppSelected
          ? t('fileUpload.dataProccesedMessageCustomApp')
          : (dataset.makeActiveDataset || datasetModals.successResponseModal) && !isCustomAppSelected
            ? t('fileUpload.successMessage', { fileName: dataset.datasetName })
            : null;
    if (dataSetPreparationResult) {
      setResponseContentProps({
        appMessage: dataSetPreparationResult,
        isLoading: false,
        isStreamComplete: false,
      });
    } else {
      // Explicitly set to null if there's no message to display
      setResponseContentProps(null);
    }
  };

  return (
    <>
      {responseContentProps && (
        <ResponseContent
          {...responseContentProps}
          modelIcon={selectedModel.image}
          modelTooltip={selectedModel.tooltip}
        />
      )}
      {(dataset.makeActiveDataset || ShowAgentActivationDialogOnChat || ShowTeamActivationDialogOnChat) && (
        <DialogBox />
      )}
    </>
  );
};

export default LoadDynamicMessageOnChat;
