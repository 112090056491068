import { Avatar, Typography } from '@mui/material';
import logo from '../assets/ai_logo.svg';
import UsePageNavigation from '../components/sharedComponents/customHooks/usePageNavigation/UsePageNavigation';

interface LogoProps {
  externalId: string;
}

function Logo({ externalId }: LogoProps) {
  const { isChatPage, isWelcomePage } = UsePageNavigation();
  return (
    <>
      {externalId === 'wipro' ? (
        <Avatar
          src={logo}
          alt="logo"
          data-testid="logo"
          sx={{
            width: isChatPage || isWelcomePage ? '170px' : { xs: '170px', md: '370px', lg: '370px' },
            height: 'auto',
            borderRadius: '0',
            margin: '0 auto',
          }} // Adjust size as needed
        />
      ) : (
        <Typography>{externalId}</Typography>
      )}
    </>
  );
}

export default Logo;
