import { chatPageNavigation } from '../../../../../components/staticComponents/StaticHtmlGenerator';
import { ActiveNavItemContext } from '../../../../../components/rightPanel/RightPanelContext';
import UsePageNavigation from '../../../../../components/sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import Api from '../../../../../data/api/Api';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from '../../../../../reduxStore/redux-hooks';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { MyTeamsData } from '../myTeams/MyTeams';
import { refreshTeamsList, teamSharedUsers } from '../../../../../reduxStore/slices/TeamsSlice';
import { GraphAPIResponse } from '../../agents/shareAgent/ShareAgent';
import { closeBasicPopoverManually } from '../../../../../reduxStore/slices/CommonSlice';

interface ShareTeamProps {
  setShowShareTeamDialog: (showTeamDialog: boolean) => void;
  team: MyTeamsData;
  selectedTeamSharedUsers?: AIAssetSharedUserDetails[];
  isNavigatedThroughManageShare?: boolean;
}
export interface AIAssetSharedUserDetails {
  id: string;
  name: string;
}

function ShareTeam({
  setShowShareTeamDialog,
  team,
  selectedTeamSharedUsers,
  isNavigatedThroughManageShare,
}: ShareTeamProps) {
  const { isWelcomePage } = UsePageNavigation();
  const navigate = useNavigate();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const { t } = useTranslation();
  const [userEmails, setUserEmails] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  const [isShareTeamLoading, setIsShareTeamLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const closeShareTeamModal = () => {
    setShowShareTeamDialog(false);
    if (!isNavigatedThroughManageShare) {
      setActiveNavItem(null);
    }
    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };

  const storeEmails = (emails: string) => {
    setUserEmails(emails);
  };

  const shareTeamWithUsers = async () => {
    let checkAlreadySharedTeamUser = false;
    if (userEmails) {
      setIsShareTeamLoading(true);
      try {
        const listOfUserEmails = userEmails
          .split(',')
          .map((email) => email.trim())
          .filter((email) => email);

        const teamSharedUserDetails: AIAssetSharedUserDetails[] = [];

        const emailValidationPromises = listOfUserEmails.map(async (email) => {
          const isEmailValid = (await Api.fetchUserByEmail(email)) as GraphAPIResponse;
          if (!isEmailValid.data.value.length) {
            throw new Error(`Invalid email: ${email}`);
          }
          if (selectedTeamSharedUsers?.find((sharedUser) => sharedUser.id === isEmailValid.data.value[0].id)) {
            checkAlreadySharedTeamUser = true;
            enqueueSnackbar(t('quickView.alreadySharedTeam', { userName: isEmailValid.data.value[0].displayName }));
            return;
          }
          teamSharedUserDetails.push({
            id: isEmailValid.data.value[0].id,
            name: isEmailValid.data.value[0].displayName,
          });

          return email;
        });
        // Wait for all email validation promises to resolve.
        await Promise.all(emailValidationPromises);

        if (!checkAlreadySharedTeamUser) {
          const shareTeamResponse = await Api.shareTeam(
            team._id,
            teamSharedUserDetails.map((user) => user.id)
          );
          if (shareTeamResponse.data) {
            if (isNavigatedThroughManageShare) {
              dispatch(
                teamSharedUsers({
                  users: teamSharedUserDetails,
                })
              );
              dispatch(
                showUserActionContentOnChat(
                  t('rightPanel.team.createTeam.activateTeamSharedWithNewUsers', {
                    activeTeamName: team.name,
                  })
                )
              );
            }
          } else {
            enqueueSnackbar(t('quickView.UserIsNotOwnerOfTeam'));
          }
          dispatch(refreshTeamsList(true));
          dispatch(closeBasicPopoverManually(true));
          closeShareTeamModal();
        } else {
          checkAlreadySharedTeamUser = false;
        }
        setIsShareTeamLoading(false);
      } catch (error) {
        setIsShareTeamLoading(false);
        enqueueSnackbar((error as Error).toString(), { variant: 'error' });
      }
    }
  };

  return (
    <Dialog fullWidth sx={{ borderRadius: '4px' }} open={true} aria-labelledby="responsive-dialog-title">
      <Box padding={'40px'}>
        <DialogTitle id="responsive-dialog-title" textAlign={'center'}>
          <Typography fontSize={24} fontWeight={700}>
            {t('rightPanel.team.shareTeam.dialogTitle')}
          </Typography>
          <Divider sx={{ padding: '10px' }} />
        </DialogTitle>

        <DialogContent sx={{ paddingTop: '20px !important' }}>
          <DialogContentText paddingBottom={'5px'} fontWeight={700} color={'#0B0C0C'}>
            {t('rightPanel.team.shareTeam.dialogContent')}
          </DialogContentText>
          <TextField
            id="email"
            name="email"
            type="email"
            fullWidth
            variant="standard"
            placeholder={t('rightPanel.team.shareTeam.inputPlaceHolder')}
            onChange={(event) => storeEmails(event.target.value)}
          />

          {selectedTeamSharedUsers && selectedTeamSharedUsers.length > 0 && (
            <Typography sx={{ color: '#7E8286', fontSize: '12px', fontWeight: '500', paddingTop: '10px' }}>
              {t('rightPanel.sharedUsers')}
            </Typography>
          )}

          {selectedTeamSharedUsers &&
            selectedTeamSharedUsers.map((sharedUser: AIAssetSharedUserDetails) => (
              <Chip
                sx={{
                  margin: '5px 0px',
                  marginRight: '10px',
                  padding: '4px 10px',
                  borderRadius: '4px',
                  backgroundColor: '#3F3F3F',
                  color: '#F2F2F2',
                  position: 'relative',
                }}
                key={sharedUser.id}
                label={sharedUser.name}
              />
            ))}
        </DialogContent>

        <DialogActions sx={{ paddingTop: '40px' }}>
          <Button
            sx={{ textTransform: 'none', textDecoration: 'underline' }}
            autoFocus
            size="small"
            onClick={closeShareTeamModal}
          >
            {t('rightPanel.cancel')}
          </Button>
          <LoadingButton
            sx={{ textTransform: 'none', backgroundColor: '#3C82F6' }}
            variant="contained"
            loading={isShareTeamLoading}
            onClick={shareTeamWithUsers}
            disabled={!userEmails}
          >
            {t('rightPanel.share')}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default ShareTeam;
