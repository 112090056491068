import ReactGA from 'react-ga4';
import {
  tabClickText,
  buttonClickText,
  eventCategoryText,
  pageTypeText,
  pageViewText,
  gaMeasurementIDDev,
  gaMeasurementIDStaging,
  gaMeasurementIDProduction,
  stagingEnv,
  prodEnv,
} from '../../staticComponents/StaticHtmlGenerator';

export function LogTabEventToGA(action: string) {
  LogEvent(action, tabClickText);
}

export function LogButtonEventToGA(action: string) {
  LogEvent(action, buttonClickText);
}

export function LogPageViewToGA(page: string) {
  const title = (page ? page.replace(/\//g, '') : '') + ' ' + pageTypeText;

  ReactGA.send({ hitType: pageViewText, page: page, title: title });
}

export function InitializeGA() {
  ReactGA.initialize(GetMeasurementID());
}

function LogEvent(action: string, label: string) {
  ReactGA.event({ category: eventCategoryText, action: action + ' ' + label, label: label });
}

function GetMeasurementID() {
  const { hostname } = window.location;

  return hostname.startsWith(stagingEnv)
    ? gaMeasurementIDStaging
    : hostname.startsWith(prodEnv)
      ? gaMeasurementIDProduction
      : gaMeasurementIDDev;
}
