import { chatPageNavigation } from '../../../../../components/staticComponents/StaticHtmlGenerator';
import { ActiveNavItemContext } from '../../../../../components/rightPanel/RightPanelContext';
import UsePageNavigation from '../../../../../components/sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import Api from '../../../../../data/api/Api';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch } from '../../../../../reduxStore/redux-hooks';
import { agentSharedUsers, refreshAgentsList } from '../../../../../reduxStore/slices/AgentsSlice';
import { showUserActionContentOnChat } from '../../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { MyAgentsData } from '../myAgents/MyAgents';
import { AIAssetSharedUserDetails } from '../../teams/shareTeam/ShareTeam';
import { closeBasicPopoverManually, setOpenCreateAgentOrTeamPage } from '../../../../../reduxStore/slices/CommonSlice';

interface ShareAgentProps {
  setShowShareAgentDialog: (showAgentDialog: boolean) => void;
  agent: MyAgentsData;
  selectedAgentSharedUsers?: AIAssetSharedUserDetails[];
  isNavigatedThroughManageShare?: boolean;
  isNavigatedThroughQuickView?: boolean;
}

interface User {
  id: string;
  userPrincipalName: string;
  displayName: string;
  mail: string;
  mailNickname: string;
}

export interface GraphAPIResponse {
  data: {
    '@odata.context': string;
    value: User[];
  };
}

function ShareAgent({
  setShowShareAgentDialog,
  agent,
  selectedAgentSharedUsers,
  isNavigatedThroughManageShare,
  isNavigatedThroughQuickView,
}: ShareAgentProps) {
  const { isWelcomePage } = UsePageNavigation();
  const navigate = useNavigate();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const { t } = useTranslation();
  const [userEmails, setUserEmails] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  const [isShareAgentLoading, setIsShareAgentLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const closeShareAgentModal = () => {
    setShowShareAgentDialog(false);
    if (isNavigatedThroughQuickView) {
      return;
    }
    if (!isNavigatedThroughManageShare) {
      setActiveNavItem(null);
    }
    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
    dispatch(setOpenCreateAgentOrTeamPage(false));
  };

  const storeEmails = (emails: string) => {
    setUserEmails(emails);
  };

  const shareAgentWithUsers = async () => {
    let checkAlreadySharedAgentUser = false;
    if (userEmails) {
      setIsShareAgentLoading(true);
      try {
        const listOfUserEmails = userEmails
          .split(',')
          .map((email) => email.trim())
          .filter((email) => email);

        const agentSharedUserDetails: AIAssetSharedUserDetails[] = [];

        const emailValidationPromises = listOfUserEmails.map(async (email) => {
          const isEmailValid = (await Api.fetchUserByEmail(email)) as GraphAPIResponse;
          if (!isEmailValid.data.value.length) {
            throw new Error(`Invalid email: ${email}`);
          }
          if (
            selectedAgentSharedUsers?.filter((sharedUser) => sharedUser.id === isEmailValid.data.value[0].id).length
          ) {
            checkAlreadySharedAgentUser = true;
            enqueueSnackbar(t('quickView.alreadySharedAgent', { userName: isEmailValid.data.value[0].displayName }));
          } else {
            agentSharedUserDetails.push({
              id: isEmailValid.data.value[0].id,
              name: isEmailValid.data.value[0].displayName,
            });
          }
          return email;
        });

        // Wait for all email validation promises to resolve.
        await Promise.all(emailValidationPromises);
        if (!checkAlreadySharedAgentUser) {
          await Api.shareAgent(
            agent._id,
            agentSharedUserDetails.map((user) => user.id)
          );

          if (!isNavigatedThroughQuickView) {
            dispatch(
              agentSharedUsers({
                users: agentSharedUserDetails,
              })
            );
          }

          if (isNavigatedThroughManageShare && !isNavigatedThroughQuickView) {
            dispatch(
              showUserActionContentOnChat(
                t('rightPanel.agent.activateAgentSharedWithNewUsers', {
                  activeAgentName: agent.name,
                })
              )
            );
          }

          if (isNavigatedThroughQuickView) {
            enqueueSnackbar(t('quickView.shareAgent', { agentName: agent.name }));
          }

          dispatch(refreshAgentsList(true));
          dispatch(closeBasicPopoverManually(true));
          closeShareAgentModal();
        } else {
          checkAlreadySharedAgentUser = false;
        }
        setIsShareAgentLoading(false);
        dispatch(setOpenCreateAgentOrTeamPage(false));
      } catch (error) {
        setIsShareAgentLoading(false);
        enqueueSnackbar((error as Error).toString(), { variant: 'error' });
      }
    }
  };

  return (
    <Dialog fullWidth sx={{ borderRadius: '4px' }} open={true} aria-labelledby="responsive-dialog-title">
      <Box padding={'40px'}>
        <DialogTitle id="responsive-dialog-title" textAlign={'center'}>
          <Typography fontSize={24} fontWeight={700}>
            {t('rightPanel.agent.shareAgent.dialogTitle')}
          </Typography>
          <Divider sx={{ padding: '10px' }} />
        </DialogTitle>

        <DialogContent sx={{ paddingTop: '20px !important' }}>
          <DialogContentText paddingBottom={'5px'} fontWeight={700} color={'#0B0C0C'}>
            {t('rightPanel.agent.shareAgent.dialogContent')}
          </DialogContentText>
          <TextField
            id="email"
            name="email"
            type="email"
            fullWidth
            variant="standard"
            placeholder={t('rightPanel.agent.shareAgent.inputPlaceHolder')}
            onChange={(event) => storeEmails(event.target.value)}
          />

          {selectedAgentSharedUsers && selectedAgentSharedUsers.length > 0 && (
            <Typography sx={{ color: '#7E8286', fontSize: '12px', fontWeight: '500', paddingTop: '10px' }}>
              {t('rightPanel.sharedUsers')}
            </Typography>
          )}

          {selectedAgentSharedUsers &&
            selectedAgentSharedUsers.map((sharedUser: AIAssetSharedUserDetails) => (
              <Chip
                sx={{
                  margin: '5px 0px',
                  marginRight: '10px',
                  padding: '4px 10px',
                  borderRadius: '4px',
                  backgroundColor: '#3F3F3F',
                  color: '#F2F2F2',
                  position: 'relative',
                }}
                key={sharedUser.id}
                label={sharedUser.name}
              />
            ))}
        </DialogContent>

        <DialogActions sx={{ paddingTop: '40px' }}>
          <Button
            sx={{ textTransform: 'none', textDecoration: 'underline' }}
            autoFocus
            size="small"
            onClick={closeShareAgentModal}
          >
            {t('rightPanel.cancel')}
          </Button>
          <LoadingButton
            sx={{ textTransform: 'none', backgroundColor: '#3C82F6' }}
            variant="contained"
            loading={isShareAgentLoading}
            onClick={shareAgentWithUsers}
            disabled={!userEmails}
          >
            {t('rightPanel.share')}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default ShareAgent;
