import {
  chatPageNavigation,
  hiddenMyAgentsList,
  loggedInUserId,
} from '../../../../components/staticComponents/StaticHtmlGenerator';
import {
  MyAgentsData,
  MyAgentsDataResponse,
} from '../../../../components/rightPanel/subpanel/agents/myAgents/MyAgents';
import Api from '../../../../data/api/Api';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Card, CardContent, Skeleton, Tab, Typography } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ImageButton from '../../../../components/sharedComponents/ImageButton';
import * as assets from '../../../../assets';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ActiveNavItemContext } from '../../../../components/rightPanel/RightPanelContext';
import { setOpenCreateAgentOrTeamPage, setRightPanelOpen } from '../../../../reduxStore/slices/CommonSlice';
import DeleteModal from '../../../../components/sharedComponents/DeleteModal';
import ShareAgent from '../../../../components/rightPanel/subpanel/agents/shareAgent/ShareAgent';
import { useSnackbar } from 'notistack';
import { AIAssetSharedUserDetails } from '../../../../components/rightPanel/subpanel/teams/shareTeam/ShareTeam';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../reduxStore/redux-hooks';
import { activeAgentMethod, confirmAgentUpdate, updateAgent } from '../../../../reduxStore/slices/AgentsSlice';
import { showUserActionContentOnChat } from '../../../../reduxStore/slices/NotifyUserActionContentSlice';

interface AgentOwners {
  [agentOwnerId: string]: string;
}

const AgentsInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filterAgentTabs = [t('quickView.allAgents')];
  const [initialFilterAgentTab, setInitialFilterAgentTab] = useState<string>(filterAgentTabs[0]);
  const [loading, setLoading] = useState<boolean>(true);
  const [allAgents, setAllAgents] = useState<MyAgentsData[]>([]);
  const [agentOwners, setAgentOwners] = useState<AgentOwners>({});
  const [isAgentDeleteModalOpen, setIsAgentDeleteModalOpen] = useState<boolean>(false);
  const [selectedAgent, setSelectedAgent] = useState<MyAgentsData>();
  const [refreshAllAgentsList, setRefreshAllAgentsList] = useState<boolean>(false);
  const [showShareAgentDialog, setShowShareAgentDialog] = useState<boolean>(false);
  const [selectedAgentSharedUsers, setSelectedAgentSharedUsers] = useState<AIAssetSharedUserDetails[]>([]);
  const { isCreateAgentOrTeamPageOpen } = useAppSelector((state) => state.commonSlice);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);

  // UseEffect hook to fetch the list of agents on component mount
  useEffect(() => {
    if (loading || !isCreateAgentOrTeamPageOpen) {
      fetchMyAgentsList();
    }
  }, [refreshAllAgentsList, isCreateAgentOrTeamPageOpen]);

  // Function to fetch the list of agents from the API
  const fetchMyAgentsList = () => {
    Api.getMyAgentsList()
      .then(async (response) => {
        const allAgentsListResp = response as MyAgentsDataResponse;
        allAgentsListResp.data = allAgentsListResp.data.filter(
          (agent) => !hiddenMyAgentsList.includes(agent.name.toLowerCase())
        );
        const sortedAgents = allAgentsListResp.data.sort((a, b) => a.name.localeCompare(b.name));
        // Take only the first 6 agents for the quick view.
        setAllAgents(sortedAgents.slice(0, 6));

        await fetchAndStoreAgentOwnersData(sortedAgents.slice(0, 6));
        setLoading(false);
        setRefreshAllAgentsList(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error in fetching all agents list:', error);
      });
  };

  const fetchAndStoreAgentOwnersData = async (agents: MyAgentsData[]) => {
    // Fetch agent owners for each agent
    const agentOwnerPromises = agents.map(async (agent) => {
      const agentOwnerDetails = await Api.getUserDetailsByIds(agent.owners);
      return agentOwnerDetails;
    });

    // Wait for all agents owner data to be fetched.
    const agentOwnerData = await Promise.all(agentOwnerPromises);

    // Transform the owner data into a single object where keys are owner IDs and values are owner names.
    const agentOwnerIdAndNameMapping = agentOwnerData.reduce((acc, agentOwnerDetails) => {
      const agentOwnerId = Object.keys(agentOwnerDetails)[0];
      acc[agentOwnerId] = agentOwnerDetails[agentOwnerId];
      return acc;
    }, {});

    setAgentOwners(agentOwnerIdAndNameMapping);
  };

  const openDeleteAgentModal = (agent: MyAgentsData) => {
    setSelectedAgent(agent);
    setIsAgentDeleteModalOpen(true);
  };

  // Function to close the delete agent modal
  const closeAgentDeleteModal = () => {
    setIsAgentDeleteModalOpen(false);
  };

  const openShareAgentModal = (agent: MyAgentsData) => {
    fetchAndStoreAgentSharedUserDetails(agent);
    setSelectedAgent(agent);
    setShowShareAgentDialog(true);
  };

  const closeShareAgentDialog = () => {
    setShowShareAgentDialog(false);
    setLoading(true);
    setRefreshAllAgentsList(true);
  };

  const openCreateAgentPage = () => {
    dispatch(setRightPanelOpen(true));
    dispatch(setOpenCreateAgentOrTeamPage(true));
    setActiveNavItem(t('welcomepage.Agent'));
  };

  const openEditAgentPage = (agent: MyAgentsData) => {
    dispatch(confirmAgentUpdate(true));
    dispatch(updateAgent(agent));
    openCreateAgentPage();
  };

  const fetchAndStoreAgentSharedUserDetails = async (agent: MyAgentsData) => {
    try {
      // Fetch user details using the acl.users from the original Agent interface
      const aclResponse = await Api.getUserDetailsByIds(agent.acl?.users || []);

      const agentSharedUserList = (agent.acl?.users || []).map((id) => ({
        id,
        name: aclResponse[id] || id,
      }));
      setSelectedAgentSharedUsers(agentSharedUserList);
    } catch (error) {
      enqueueSnackbar(t('snackbar.alertMessage.errorGetGraphData'));
      console.log(error);
    }
  };

  // Function to make agent active
  const makeAgentActive = (agentData: MyAgentsData) => {
    dispatch(activeAgentMethod(agentData));
    dispatch(showUserActionContentOnChat(t('rightPanel.agent.activateAgent', { activeAgentName: agentData.name })));
    navigate(chatPageNavigation);
  };

  const viewAllAgents = () => {
    dispatch(setOpenCreateAgentOrTeamPage(false));
    dispatch(setRightPanelOpen(true));
    setActiveNavItem(t('welcomepage.Agent'));
  };

  // Function to confirm the deletion of the agent.
  const confirmDelete = async () => {
    await deleteAgent();
    setIsAgentDeleteModalOpen(false);
  };

  const deleteAgent = async () => {
    try {
      if (selectedAgent) {
        const deleteAgentResponse = await Api.deleteAgent(selectedAgent._id);
        if (deleteAgentResponse) {
          enqueueSnackbar(t('quickView.deleteAgent', { agentName: selectedAgent.name }));
          setLoading(true);
          setRefreshAllAgentsList(true);
        }
      }
    } catch (error) {
      console.error('Error deleting agent:', error);
    }
  };

  const agentCardActions = [
    {
      icon: assets.shareIcon,
      style: { padding: '0px 5px' },
      onClick: (agent: MyAgentsData, event: React.MouseEvent) => {
        event.stopPropagation();
        openShareAgentModal(agent);
      },
    },
    {
      icon: assets.pencilIcon,
      style: { padding: '0px 5px' },
      onClick: (agent: MyAgentsData, event: React.MouseEvent) => {
        event.stopPropagation();
        openEditAgentPage(agent);
      },
    },
    {
      icon: assets.delete,
      style: { padding: '0px 5px' },
      onClick: (agent: MyAgentsData, event: React.MouseEvent) => {
        event.stopPropagation();
        openDeleteAgentModal(agent);
      },
    },
  ];

  const renderAllAgents = () => {
    return allAgents.map((agent, index) => {
      return (
        <Card
          key={index}
          sx={{
            border: '1px solid #e5e7eb',
            backgroundColor: '#F9FAFB',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            overflow: 'visible',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={() => makeAgentActive(agent)}
        >
          <CardContent sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 700,
                  maxWidth: '12ch',
                }}
              >
                {agent.name}
              </Typography>
              <Box>
                {agentCardActions.map((action, index) => {
                  return (
                    <ImageButton
                      key={index}
                      disabled={agent.owners[0] !== loggedInUserId()}
                      srcFile={action.icon}
                      style={{
                        ...action.style,
                        opacity: agent.owners[0] !== loggedInUserId() ? 0.5 : 1, // Set opacity for disabled state
                        pointerEvents: agent.owners[0] !== loggedInUserId() ? 'none' : 'auto', // Ensure click events are disabled
                      }}
                      handleClick={(event) => {
                        if (action.onClick) {
                          action.onClick(agent, event);
                        }
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
            {[t('quickView.agentTypeLabel'), t('quickView.agentLLMLabel'), t('quickView.ownerLabel')].map(
              (label, index) => (
                <Typography
                  key={index}
                  sx={{
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#4B5563',
                    lineHeight: '1.5rem',
                  }}
                >
                  {label}:{' '}
                  {label === t('quickView.agentTypeLabel')
                    ? agent.type
                    : label === t('quickView.agentLLMLabel')
                      ? agent.model_name
                      : agentOwners[agent.owners[0]] || agent.owners[0]}
                </Typography>
              )
            )}
          </CardContent>
        </Card>
      );
    });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" fontWeight={'700'}>
          {t('quickView.agents')}
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              boxShadow: 'none',
              '&:hover': { boxShadow: 'none' },
            }}
            variant="outlined"
            endIcon={<NavigateNextIcon />}
            size="small"
            onClick={viewAllAgents}
          >
            {t('quickView.viewAll')}
          </Button>
          <Button
            sx={{
              textTransform: 'none',
              fontSize: '14px',
              boxShadow: 'none',
              '&:hover': { boxShadow: 'none' },
              background: '#3C82F6',
            }}
            variant="contained"
            startIcon={<AddIcon />}
            size="small"
            onClick={openCreateAgentPage}
          >
            {t('quickView.createAgent')}
          </Button>
        </Box>
      </Box>

      <TabContext value={initialFilterAgentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(_, newValue) => setInitialFilterAgentTab(newValue)}
            aria-label="filter agents tabs"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#2563EB !important',
              },
            }}
          >
            {filterAgentTabs.map((tab) => (
              <Tab
                key={tab}
                label={tab}
                value={tab}
                sx={{
                  '&.Mui-selected': {
                    color: '#2563EB',
                    fontWeight: '700',
                    textTransform: 'none',
                  },
                }}
              />
            ))}
          </TabList>
        </Box>
        {filterAgentTabs.map((tab) => (
          <TabPanel key={tab} value={tab} sx={{ paddingRight: '0px', paddingLeft: '0px' }}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', lg: '1fr 1fr 1fr' },
                gap: '30px',
              }}
            >
              {loading
                ? Array.from({ length: 6 }).map((_, index) => (
                    <Skeleton key={index} width="100%" variant="rounded" height={150} animation="wave" />
                  ))
                : renderAllAgents()}
            </Box>
          </TabPanel>
        ))}
      </TabContext>

      {isAgentDeleteModalOpen && (
        <DeleteModal
          openDeleteModal={isAgentDeleteModalOpen}
          closeDeleteModal={closeAgentDeleteModal}
          modalAPi={confirmDelete}
          dialogTitle={t('rightPanel.agent.editAgent.deleteAgent')}
          textContent={`${t('rightPanel.confirmDelete')} ${selectedAgent && selectedAgent.name}?`}
          confirmButtonText={t('rightPanel.removeButton')}
        />
      )}

      {showShareAgentDialog && selectedAgent && (
        <ShareAgent
          setShowShareAgentDialog={closeShareAgentDialog}
          agent={selectedAgent}
          selectedAgentSharedUsers={selectedAgentSharedUsers}
          isNavigatedThroughQuickView={true}
        />
      )}
    </Box>
  );
};

export default AgentsInfo;
