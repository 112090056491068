import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import Tooltip from '@mui/material/Tooltip';
interface CopyToClipboardProps {
  textToCopy: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ textToCopy }) => {
  const { t } = useTranslation();
  const [showCopiedIcon, setShowCopiedIcon] = useState(false);
  const COPY_DELAY = 2000;

  const copyResponseText = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setShowCopiedIcon(true);
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setShowCopiedIcon(false);
      }, COPY_DELAY);
    }
  };

  return (
    <>
      <Tooltip title={showCopiedIcon ? t('copyToClipboard.tooltipCopied') : t('copyToClipboard.tooltipCopy')} arrow>
        <Box
          className={showCopiedIcon ? 'done-svg-icon' : 'copy-svg-icon'}
          sx={{ cursor: showCopiedIcon ? 'default' : 'pointer' }}
          onClick={copyResponseText}
        >
          {showCopiedIcon ? <DoneRoundedIcon /> : <ContentCopyRoundedIcon />}
        </Box>
      </Tooltip>
    </>
  );
};
export default CopyToClipboard;
