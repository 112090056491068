import { Box, Input, InputLabel } from '@mui/material';
import { supportedFileTypes } from './DatasetModal';
import { setFiles, toggleModal, updateDatasetState } from '../../../../reduxStore/slices/fileUploadSlice';
import { useAppDispatch, useAppSelector } from '../../../../reduxStore/redux-hooks';
import { useTranslation } from 'react-i18next';
import { setEditDataset } from '../../../../reduxStore/slices/DataSetSlice';

interface DatasetFileInputProps {
  inputText: string;
  currentDatasetId?: string;
}

const DatasetFileInput: React.FC<DatasetFileInputProps> = ({ inputText, currentDatasetId }: DatasetFileInputProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { responseMessage } = useAppSelector((state) => state.fileUpload.fileSelection);

  const fileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files && files!.length === 0) return;
    dispatch(setFiles(Array.from(files as FileList)));
    if (inputText === t('rightPanel.newDatasets')) dispatch(toggleModal());
    if (!currentDatasetId) return;
    dispatch(
      setEditDataset({
        addFiles: true,
        datasetId: currentDatasetId,
      })
    );
    dispatch(
      updateDatasetState({
        selectedDatasetId: currentDatasetId,
      })
    );
    dispatch(toggleModal());
  };

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Input
        id={`${inputText}-file-upload`}
        type="file"
        sx={{
          position: 'absolute',
          opacity: '0',
          pointerEvents: 'auto',
        }}
        inputProps={{
          multiple: true,
          accept: supportedFileTypes,
        }}
        onChange={fileInput}
        disabled={responseMessage}
      />
      <InputLabel
        sx={{
          padding: 0,
          color: '#256AFF',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        htmlFor={`${inputText}-file-upload`}
      >
        {inputText}
      </InputLabel>
    </Box>
  );
};

export default DatasetFileInput;
