import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as assets from '../../../../assets';
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import DeleteModal from '../../../sharedComponents/DeleteModal';
import { useTranslation } from 'react-i18next';
import Api from '../../../../data/api/Api';
import { useAppDispatch, useAppSelector } from '../../../../reduxStore/redux-hooks';
import { Controller, useForm } from 'react-hook-form';
import {
  currentDataSet,
  refreshDatasetList,
  selectedDataSet,
  sharedDataSet,
} from '../../../../reduxStore/slices/DataSetSlice';
import { AccountCircle, BoyOutlined, Check, Close } from '@mui/icons-material';
import ImageButton from '../../../sharedComponents/ImageButton';
import User from '../../../../helpers/User';
import { datasetSharedUser } from './DatasetList';
import { SectionDivider } from '../../../staticComponents/StaticHtmlGenerator';
import ShareDataset from './ShareDataset';
import { ActiveNavItemContext } from '../../../../components/rightPanel/RightPanelContext';
import UsePageNavigation from '../../../../components/sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import { chatPageNavigation } from '../../../../components/staticComponents/StaticHtmlGenerator';
import { enqueueSnackbar } from 'notistack';
import DatasetFileInput from './DatasetFileInput';
import { showUserActionContentOnChat } from '../../../../reduxStore/slices/NotifyUserActionContentSlice';

interface EditDatasetProps {
  activeDataSetName: string;
  activeDataSetId: string;
  isFromDatasetList: boolean;
  currentDatasetId: string;
  currentDatasetName: string;
  isItemVisible: boolean;
  ownerListDataset: string[];
  sharedListDataset: datasetSharedUser[];
  isNavigatedThroughManageShare?: boolean;
}
interface DataSetToBeDeleted {
  datasetId: string | null;
  datasetName: string | null;
  isSharedDataset: boolean;
}

const EditDataset: React.FC<EditDatasetProps> = ({
  activeDataSetName,
  activeDataSetId,
  isFromDatasetList,
  currentDatasetId,
  currentDatasetName,
  isItemVisible,
  ownerListDataset = [],
  sharedListDataset = [],
  isNavigatedThroughManageShare,
}) => {
  const userName = useAppSelector((state) => state.commonSlice.userName);
  const [isDeleteDatasetModalOpen, setIsDeleteDatasetModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [dataSetToBeDeleted, setDataSetToBeDeleted] = useState<DataSetToBeDeleted>({
    datasetId: null,
    datasetName: null,
    isSharedDataset: false,
  });
  const [isManageShareOpen, setIsManageShareOpen] = useState(false);
  const { isWelcomePage } = UsePageNavigation();
  const user = User.empty;
  const navigate = useNavigate();
  const { setActiveNavItem } = useContext(ActiveNavItemContext);
  const [datasetToRename, setDatasetToRename] = useState<boolean>(false);
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: currentDatasetName,
    },
    mode: 'onChange',
  });

  const { t } = useTranslation();
  const datasetNameToShow = isFromDatasetList ? currentDatasetName : activeDataSetName;
  const datasetIdToShow = isFromDatasetList ? currentDatasetId : activeDataSetId;
  const datasetUserPopupContent = dataSetToBeDeleted.isSharedDataset
    ? `${t('rightPanel.dataset.shareDataset.confirmRemove')} ${dataSetToBeDeleted.datasetName}?`
    : `${t('rightPanel.confirmDelete')} ${datasetNameToShow}?`;
  const datasetUserPopupButton = dataSetToBeDeleted.isSharedDataset
    ? `${t('rightPanel.dataset.shareDataset.removeButton')}`
    : `${t('rightPanel.deleteButton')}`;
  const datasetUserToBeDeleted = dataSetToBeDeleted.isSharedDataset
    ? `${t('rightPanel.deleteShareUser')}`
    : `${t('rightPanel.deleteDataset')}`;
  const dispatch = useAppDispatch();

  const clearSelectedDataSet = () => {
    dispatch(showUserActionContentOnChat(t('fileUpload.unloadMessage', { fileName: activeDataSetName })));
    dispatch(
      selectedDataSet({
        activeDataSetName: '',
        activeDataSetId: '',
      })
    );
  };

  const clearCurrentDataset = () => {
    dispatch(
      currentDataSet({
        currentDataSetName: '',
        currentDataSetId: '',
      })
    );
  };

  const clearSharedDataset = () => {
    dispatch(sharedDataSet({ sharedDataList: {} }));
  };

  const handleUnload = () => {
    isFromDatasetList ? clearCurrentDataset() : clearSelectedDataSet();
  };

  const openDeleteModal = (dataset: DataSetToBeDeleted) => {
    setIsDeleteDatasetModalOpen(true);
    setDataSetToBeDeleted(dataset);
  };

  const closeManageSharePopup = () => {
    setIsManageShareOpen(false);
    if (isNavigatedThroughManageShare) {
      setActiveNavItem(null);
    }
    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };
  const openManageSharePopup = () => {
    setIsManageShareOpen(true);
  };

  const deleteDatasetSharedUser = useCallback(async () => {
    setIsDeleting(true);
    try {
      const dataset: string[] = [dataSetToBeDeleted.datasetId as string];
      const deleteSharedUserResponse = await Api.deleteSharedUser(datasetIdToShow, dataset);
      clearSharedDataset();
      return deleteSharedUserResponse;
    } catch (error) {
      console.error('Error deleting dataset:', error);
    } finally {
      setIsDeleting(false);
      closeDatasetModal();
    }
  }, [dataSetToBeDeleted, clearSharedDataset]);

  const deleteDataset = useCallback(async () => {
    setIsDeleting(true);
    try {
      await Api.deleteDataset(dataSetToBeDeleted.datasetId!);
      clearSelectedDataSet();
      clearCurrentDataset();
    } catch (error) {
      console.error('Error deleting dataset:', error);
    } finally {
      setIsDeleting(false);
      closeDatasetModal();
      setDataSetToBeDeleted({ datasetId: null, datasetName: null, isSharedDataset: false });
    }
  }, [dataSetToBeDeleted, clearSelectedDataSet, clearCurrentDataset]);

  const closeDatasetModal = () => {
    setIsDeleteDatasetModalOpen(false);
    setDataSetToBeDeleted({ datasetId: null, datasetName: null, isSharedDataset: false });
  };

  const updateDatasetName = async () => {
    const editDatasetFormValues = getValues();
    try {
      const updateDatasetApiResponse = await Api.updateDataset(currentDatasetId, { name: editDatasetFormValues.name });
      if (updateDatasetApiResponse.response) {
        dispatch(refreshDatasetList());
        enqueueSnackbar(t('rightPanel.dataset.errorsValidationMessages.successfullyRenamed'), {
          variant: 'success',
        });
      } else {
        throw new Error(t('rightPanel.dataset.errorsValidationMessages.failedToRename'));
      }
    } catch (error) {
      enqueueSnackbar((error as Error).toString(), {
        variant: 'error',
      });
    } finally {
      setDatasetToRename(!datasetToRename);
    }
  };

  useEffect(() => {
    // Fetch data and update user on component mount
    const fetchUserData = async () => {
      const fetchUser = await User.getInstance();
      Object.assign(user, fetchUser);
    };
    fetchUserData();
  }, []);

  return (
    <div style={{ padding: '16px' }}>
      {!datasetToRename ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">{datasetNameToShow}</Typography>
        </Box>
      ) : (
        <Box sx={{ marginBottom: '0.8rem' }}>
          <Box
            sx={{
              display: 'flex',
              gap: '0.3rem',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <Controller
              control={control}
              name="name"
              rules={{
                required: t('rightPanel.dataset.errorsValidationMessages.nameRequired'),
                maxLength: { value: 20, message: t('rightPanel.dataset.errorsValidationMessages.nameCharacterLimit') },
              }}
              render={({ field }) => <TextField {...field} variant="standard" error={!!errors.name} fullWidth />}
            />
            <Check sx={{ cursor: 'pointer' }} onClick={handleSubmit(updateDatasetName)} />
            <Close
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setValue('name', currentDatasetName);
                clearErrors('name');
                setDatasetToRename(false);
              }}
            />
          </Box>
          <FormHelperText
            sx={{
              position: 'relative',
              width: '100%',
            }}
            error
          >
            {errors.name && errors.name.message}
          </FormHelperText>
        </Box>
      )}

      <List>
        {ownerListDataset.includes(userName) && (
          <ListItem
            sx={{
              color: '#256AFF',
              transition: 'all 300ms ease-in-out',
              textDecoration: 'underline',
              padding: 0,
              cursor: 'pointer',
            }}
          >
            <ListItemText onClick={() => setDatasetToRename(true)}>{t('rightPanel.rename')}</ListItemText>
          </ListItem>
        )}

        {ownerListDataset.includes(userName) && (
          <ListItem
            sx={{
              color: '#256AFF',
              transition: 'all 300ms ease-in-out',
              textDecoration: 'underline',
              padding: 0,
              cursor: 'pointer',
            }}
          >
            <DatasetFileInput inputText={t('rightPanel.addFiles')} currentDatasetId={currentDatasetId} />
          </ListItem>
        )}

        {isItemVisible && (
          <ListItem
            sx={{
              color: '#256AFF',
              transition: 'all 300ms ease-in-out',
              textDecoration: 'underline',
              padding: 0,
              cursor: 'pointer',
            }}
          >
            <ListItemText onClick={handleUnload}>{t('rightPanel.unload')}</ListItemText>
          </ListItem>
        )}
      </List>
      <List>
        {ownerListDataset.length > 0 ? (
          ownerListDataset.map((ownerDatasetUser, index) => (
            <ListItem sx={{ padding: 0, justifyContent: 'space-between' }} key={index}>
              <ListItemText sx={{ marginRight: '50px' }}>{ownerDatasetUser}</ListItemText>
              <ListItemText sx={{ textAlign: 'right' }}>
                <span
                  style={{
                    fontSize: '12px',
                    background: 'var(--global-color)',
                    color: '#fff',
                    padding: '2px 5px 3px',
                    borderRadius: '4px',
                  }}
                >
                  {t('rightPanel.dataset.ownerBadge')}
                </span>
              </ListItemText>
            </ListItem>
          ))
        ) : (
          <Typography>{t('rightPanel.dataset.ownersNotFoudMessage')}</Typography>
        )}
      </List>
      <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '10px' }} />
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex' }}>
            <SvgIcon sx={{ marginRight: '10px' }} component={AccountCircle} />
            <Typography>{t('rightPanel.dataset.sharedHeading')}</Typography>
          </Box>
          <Typography sx={{ fontWeight: 600 }}>{sharedListDataset.length}</Typography>
        </Box>
        <List sx={{ marginLeft: '25px' }}>
          {sharedListDataset.length > 0 ? (
            sharedListDataset.map((sharedDatasetUser, index) => (
              <ListItem sx={{ padding: 0, justifyContent: 'space-between' }} key={index}>
                <ListItemText>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', width: '200px', wordBreak: 'break-all' }}>
                      <SvgIcon sx={{ marginRight: '5px' }} component={BoyOutlined} />
                      <Typography sx={{ flex: 'none', fontWeight: '600', maxWidth: '170px' }}>
                        {sharedDatasetUser.name}
                      </Typography>
                    </Box>
                    {ownerListDataset.includes(userName) && (
                      <ListItemText sx={{ flex: 'none' }}>
                        <ImageButton
                          srcFile={assets.removeUser}
                          handleClick={() =>
                            openDeleteModal({
                              datasetId: sharedDatasetUser.id,
                              datasetName: sharedDatasetUser.name,
                              isSharedDataset: true,
                            })
                          }
                        />
                      </ListItemText>
                    )}
                  </Box>
                </ListItemText>
              </ListItem>
            ))
          ) : (
            <Typography>{t('rightPanel.dataset.shareNotFoudMessage')}</Typography>
          )}
        </List>
        {ownerListDataset.includes(userName) && (
          <>
            <ListItem
              sx={{
                color: '#256AFF',
                transition: 'all 300ms ease-in-out',
                textDecoration: 'underline',
                padding: 0,
                cursor: 'pointer',
              }}
              onClick={openManageSharePopup}
            >
              <ListItemText>{t('rightPanel.dataset.manageShare')}</ListItemText>
            </ListItem>
            <ShareDataset
              openShareDataset={isManageShareOpen}
              closeShareDataset={closeManageSharePopup}
              datasetId={datasetIdToShow}
              sharedListDataset={sharedListDataset}
              isNavigatedThroughManageShare={true}
              datasetName={datasetNameToShow}
            />
          </>
        )}
      </Box>
      {ownerListDataset.includes(userName) && (
        <>
          <SectionDivider sx={{ marginLeft: '0', marginRight: '0', marginBottom: '10px' }} />
          <Button
            sx={{ color: 'red', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() =>
              openDeleteModal({ datasetId: datasetIdToShow, datasetName: datasetNameToShow, isSharedDataset: false })
            }
          >
            {t('rightPanel.deleteDataset')}
          </Button>
        </>
      )}
      {isDeleteDatasetModalOpen && (
        <DeleteModal
          openDeleteModal={isDeleteDatasetModalOpen}
          closeDeleteModal={closeDatasetModal}
          modalAPi={() => {
            return dataSetToBeDeleted.isSharedDataset ? deleteDatasetSharedUser() : deleteDataset();
          }}
          dialogTitle={datasetUserToBeDeleted}
          textContent={datasetUserPopupContent}
          confirmButtonText={datasetUserPopupButton}
        />
      )}
      {isDeleting && <CircularProgress size={20} />}
    </div>
  );
};

export default EditDataset;
