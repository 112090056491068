import Api from '../../../../../../data/api/Api';
import { Autocomplete, Box, Chip, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as assets from '../../../../../../assets';
import { useAppSelector } from '../../../../../../reduxStore/redux-hooks';
import { AgentType } from '../CreateAgent';

interface AgentSkills {
  tools: string[];
}

interface ToolSet {
  skillsSelected: (skills: string[]) => void;
}

function ToolSet({ skillsSelected }: ToolSet) {
  const [toolSet, setToolSet] = useState<string[]>([]);
  const [selectedSkill, setSelectedSkill] = React.useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { updateAgentDetails, isAgentToBeUpdated } = useAppSelector((state) => state.agents);

  const handleSkillSelection = (event: React.SyntheticEvent, skill: string[]) => {
    setSelectedSkill(skill);
    skillsSelected(skill);
  };

  const deleteSelectedSkill = (deleteSkill: string) => {
    const newSkillSet = selectedSkill.filter((skill) => skill !== deleteSkill);
    setSelectedSkill(newSkillSet);
    skillsSelected(newSkillSet);
  };

  useEffect(() => {
    fetchListOfToolSet();

    //prefill the data for edit agent.
    if (isAgentToBeUpdated && updateAgentDetails.type === AgentType.Toolset) {
      setSelectedSkill(updateAgentDetails.tools as string[]);
    }
  }, []);

  const fetchListOfToolSet = async () => {
    try {
      const toolSetResponse = await Api.fetchToolSet();
      const listOfToolSet = toolSetResponse.data as AgentSkills;
      setToolSet(listOfToolSet.tools);
    } catch (error) {
      enqueueSnackbar((error as Error).toString());
    }
  };

  return (
    <Box sx={{ padding: '0px 35px' }}>
      <Typography sx={{ fontWeight: 700, color: '#0B0C0C' }}>
        {t('rightPanel.agent.createAgent.skills.selectSkills')}
      </Typography>
      <Autocomplete
        multiple
        id="toolset"
        options={toolSet}
        value={selectedSkill}
        onChange={handleSkillSelection}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <ListItem
              key={key}
              {...optionProps}
              sx={{
                '&.MuiAutocomplete-option[aria-selected="true"]': {
                  backgroundColor: '#FFFFFF !important',
                },
              }}
            >
              <ListItemText
                sx={{
                  marginLeft: '30px',
                  padding: '5px',
                  backgroundColor: selected ? '#F7FFF3' : 'transparent',
                }}
                primary={option}
              />
              <Box
                sx={{
                  marginRight: '30px',
                  paddingTop: '5px',
                  paddingRight: '5px',
                  backgroundColor: selected ? '#F7FFF3' : 'transparent',
                }}
              >
                {selected ? (
                  <img src={assets.selectCircleIcon} alt="selected icon" />
                ) : (
                  <img src={assets.plusCircleIcon} alt="select icon" />
                )}
              </Box>
            </ListItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ padding: '8px 0px' }}
            variant="standard"
            placeholder={t('rightPanel.agent.createAgent.skills.skillsPlaceHolder')}
          />
        )}
        renderTags={() => null}
      />
      {selectedSkill.length > 0 && (
        <Typography sx={{ color: '#7E8286', fontSize: '12px', fontWeight: '500' }}>
          {t('rightPanel.agent.createAgent.skills.addedSkills')}
        </Typography>
      )}
      {selectedSkill.map((skill: string) => (
        <Chip
          sx={{
            margin: '5px 0px',
            marginRight: '10px',
            padding: '4px 10px',
            borderRadius: '4px',
            backgroundColor: '#3F3F3F',
            color: '#F2F2F2',
            position: 'relative',
          }}
          key={skill}
          label={skill}
          onDelete={() => deleteSelectedSkill(skill)}
          deleteIcon={
            <Box sx={{ position: 'absolute', right: '-10px', top: '-10px' }}>
              <img src={assets.cancelIcon} alt="cancel icon" />
            </Box>
          }
        />
      ))}
    </Box>
  );
}

export default ToolSet;
