import * as React from 'react';
import Popover from '@mui/material/Popover';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../reduxStore/redux-hooks';
import { closeBasicPopoverManually } from '../../reduxStore/slices/CommonSlice';

interface BasicPopoverProps {
  srcComponent: React.ReactNode;
  contentComponent: React.ReactNode;
  onOpen?: () => void;
}

const BasicPopover: React.FC<BasicPopoverProps> = ({ srcComponent, contentComponent, onOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);
  const { closeBasicPopover } = useAppSelector((state) => state.commonSlice);
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (anchorEl && onOpen) {
      onOpen(); // Call the function to fetch data when popover is opened
    }
    if (closeBasicPopover && anchorEl) {
      setAnchorEl(null);
      dispatch(closeBasicPopoverManually(false));
    }
  }, [anchorEl, onOpen, closeBasicPopover]);

  const popoverOpen = Boolean(anchorEl);
  const popOverId = popoverOpen ? 'simple-popover' : undefined;
  return (
    <>
      <Box sx={{ marginTop: '5px' }} aria-describedby={popOverId} onClick={handleClick}>
        {srcComponent}
      </Box>

      {contentComponent && (
        <Popover
          id={popOverId}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {contentComponent}
        </Popover>
      )}
    </>
  );
};

export default BasicPopover;
