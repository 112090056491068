import { Box, List, ListItemButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as assets from '../../../../assets';
import { SectionDivider } from '../../../../components/staticComponents/StaticHtmlGenerator';
import BasicPopover from '../../../../components/sharedComponents/BasicPopover';
import ImageButton from '../../../../components/sharedComponents/ImageButton';
import { buttonStyle } from '../dataset/Dataset';
import { useAppSelector } from '../../../../reduxStore/redux-hooks';
import MyTeams, { MyTeamsData } from './myTeams/MyTeams';
import EditTeam from './editTeam/EditTeam';
import { lengthOfVisibleAgentOrTeamName } from '../agents/myAgents/MyAgents';

function Teams() {
  const { t } = useTranslation();
  const { activeTeam } = useAppSelector((state) => state.teams);

  const renderEditActiveTeamPopover = (team: MyTeamsData) => {
    return (
      <BasicPopover
        srcComponent={
          <Box className="hover-showDotsButton">
            <ImageButton srcFile={assets.dots} style={buttonStyle} />
          </Box>
        }
        contentComponent={<EditTeam team={team} isActiveTeam={true} />}
      />
    );
  };

  return (
    <Box id={'teams-2nd-level-content'}>
      <Box className="active-team" marginBottom={'25px'}>
        <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
          {t('rightPanel.team.activeTeam')}
        </Typography>

        <Box>
          {!activeTeam.name ? (
            <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
              {t('rightPanel.team.noActiveTeam')}
            </Typography>
          ) : (
            <List>
              <ListItemButton
                key={activeTeam._id}
                className="teams-list"
                sx={{
                  padding: '5px 10px',
                  // paddingLeft: '0px',
                  width: '100%',
                }}
              >
                {/* <ListItemAvatar sx={{ alignSelf: 'start', minWidth: '50px' }}>
                  <Avatar className="avatar" alt="default team" src={assets.ai360Agent} />
                </ListItemAvatar> */}
                <Box width={'100%'} overflow={'hidden'}>
                  <Tooltip
                    title={activeTeam.name.length > lengthOfVisibleAgentOrTeamName ? activeTeam.name : null}
                    arrow
                  >
                    <Typography
                      color={'#0B0C0C'}
                      fontWeight={700}
                      fontSize={'16px'}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                    >
                      {activeTeam.name}
                    </Typography>
                  </Tooltip>
                </Box>
                <span className="buttonShowOnHover">{renderEditActiveTeamPopover(activeTeam)}</span>
              </ListItemButton>
            </List>
          )}
        </Box>
      </Box>

      <Box className="my-teams" marginBottom={'25px'}>
        <MyTeams />
      </Box>

      <SectionDivider sx={{ marginTop: '40px', marginLeft: '0px', marginRight: '0px' }} />
    </Box>
  );
}

export default Teams;
