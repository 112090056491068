import React from 'react';
import * as assets from '../../assets';
import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyToClipboard from '../../components/sharedComponents/copyToClipBoard/CopyToClipboard';
import rehypeRaw from 'rehype-raw';

interface ResponseContentProps {
  appMessage: string;
  modelIcon: string;
  modelTooltip?: string;
  isLoading: boolean;
  isStreamComplete: boolean;
}

export const components = {
  code({ node, inline, className, children, ...props }: any) {
    const match = /language-(\w+)/.exec(className || '');
    const codeContent = String(children).replace(/\n$/, '');
    return !inline && match ? (
      <div style={{ position: 'relative' }}>
        <SyntaxHighlighter style={oneLight} language={match[1]} PreTag="div" {...props}>
          {codeContent}
        </SyntaxHighlighter>
        <Box sx={{ position: 'absolute', top: '10px', zIndex: '1', right: '10px' }}>
          <CopyToClipboard textToCopy={codeContent} />
        </Box>
      </div>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },

  //this helps in adding target attribute to generated link so that links can be opened in new tab.
  a({ href, children, ...props }: any) {
    return (
      <a href={href} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    );
  },
};

const ResponseContent: React.FC<ResponseContentProps> = ({
  appMessage,
  isLoading,
  modelIcon,
  modelTooltip,
  isStreamComplete,
}) => {
  return (
    <>
      <Box className="msgBox">
        <Box className="welcome-icon">
          <img id="changeicon" src={modelIcon} alt="" />
          {modelTooltip && (
            <Box className="toolTip">
              <strong>{modelTooltip}</strong>
            </Box>
          )}
        </Box>
        <Box className="chat-content" id="current-response-box" sx={{ '& p': { marginTop: 0 } }}>
          {isLoading ? (
            <img src={assets.loading} id="loadingimg" alt="" />
          ) : (
            <div>
              <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} components={components}>
                {appMessage}
              </ReactMarkdown>
            </div>
          )}
        </Box>
        {isStreamComplete && !isLoading && (
          <Box sx={{ display: 'inline-block', position: 'absolute', right: 0, bottom: 0 }}>
            <CopyToClipboard textToCopy={appMessage} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ResponseContent;
