import React, { useEffect, useState, useCallback, useContext } from 'react';
import Api, { SkillId } from '../../../../data/api/Api';
import CircularProgress from '@mui/material/CircularProgress';
import SearchList from '../../../sharedComponents/SearchList';
import BasicPopover from '../../../sharedComponents/BasicPopover';
import EditDataset from './EditDataset';
import * as assets from '../../../../assets';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../reduxStore/redux-hooks';
import {
  selectedDataSet,
  ownerDataSet,
  sharedDataSet,
  currentDataSet,
} from '../../../../reduxStore/slices/DataSetSlice';
import { updateDatasetState } from '../../../../reduxStore/slices/fileUploadSlice';
import { useTranslation } from 'react-i18next';
import ImageButton from '../../../sharedComponents/ImageButton';
import { useSnackbar } from 'notistack';
import { showUserActionContentOnChat } from '../../../../reduxStore/slices/NotifyUserActionContentSlice';
import { buttonStyle } from './Dataset';
import { chatPageNavigation, customAppsSkillIds } from '../../../../components/staticComponents/StaticHtmlGenerator';
import UsePageNavigation from '../../../sharedComponents/customHooks/usePageNavigation/UsePageNavigation';
import {
  SowCommitAiQueryResponse,
  SowPredictorQueryResponse,
  saveSowCommitAiQueryResponse,
  saveSowPredictorQueryResponse,
  saveAdvanceSummaryResponse,
  AdvanceSummaryResponse,
} from '../../../../reduxStore/slices/CustomAppsSlice';
import { AppSkillId } from '../../../../components/staticComponents/StaticHtmlGenerator';
import { UseTrackedNavigate } from '../../../../components/sharedComponents/customHooks/useTrackedNavigate/UseTrackedNavigate';
import { ActiveNavItemContext } from '../../RightPanelContext';
import useResetActiveItems from '../../../../components/sharedComponents/customHooks/useResetActiveItems/useResetActiveItem';

interface Acl {
  users: string[]; // Array of user IDs
}
export interface Dataset {
  _id: string;
  name: string;
  owners: string[];
  acl: Acl;
}
interface SearchListItem {
  _id: string;
  name: string;
  owners: string[];
  users: string[];
}
export interface datasetSharedUser {
  id: string;
  name: string;
}

interface DatasetListProps {
  selectedApp: string;
}

const DatasetList: React.FC<DatasetListProps> = ({ selectedApp }) => {
  const dispatch = useAppDispatch();
  const { activeNavItem } = useContext(ActiveNavItemContext);
  const { refreshDatasetList } = useAppSelector((state) => state.dataset);
  const [datasets, setDatasets] = useState<SearchListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [ownersData, setOwnersData] = useState<{ [key: string]: string[] }>({});
  const [datasetSharedUsersList, setDatasetSharedUsersList] = useState<{ [key: string]: datasetSharedUser[] }>({});
  const navigate = UseTrackedNavigate();
  const { isWelcomePage } = UsePageNavigation();
  const { isCustomAppSelected, selectedCustomAppSkill } = useAppSelector((state) => state.customApps.choosenCustomApp);
  const resetActiveItems = useResetActiveItems();

  const fetchListofDatasets = useCallback(async () => {
    try {
      deactivateDataset();
      let loadDatasets;
      if (activeNavItem === t('welcomepage.Documents')) {
        loadDatasets = await Api.listDatasets(true, true, AppSkillId.DocCompletion);
      } else {
        loadDatasets = await Api.listDatasets(true, true, selectedCustomAppSkill as SkillId);
      }

      const datasets = loadDatasets.data as Dataset[];
      const filteredAndSortedDatasets = datasets
        .filter((dataset) => dataset.name)
        .sort((a, b) => a.name.localeCompare(b.name));

      if (!filteredAndSortedDatasets.length) enqueueSnackbar(t('snackbar.alertMessage.noDataset'));

      const searchListItems = filteredAndSortedDatasets.map((dataset) => ({
        _id: dataset._id,
        name: dataset.name,
        owners: dataset.owners,
        users: dataset.acl?.users || [], // Fetch users from acl
      }));

      setDatasets(searchListItems);
    } catch (err) {
      enqueueSnackbar((err as Error).toString());
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, t, selectedApp]);

  useEffect(() => {
    fetchListofDatasets();
  }, [fetchListofDatasets, refreshDatasetList]);

  const deactivateDataset = () => {
    if (isCustomAppSelected && activeNavItem === t('welcomepage.Documents')) {
      dispatch(
        selectedDataSet({
          activeDataSetId: '',
          activeDataSetName: '',
        })
      );
      dispatch(
        currentDataSet({
          currentDataSetName: '',
          currentDataSetId: '',
        })
      );
      dispatch(
        updateDatasetState({
          makeActiveDataset: false,
        })
      );
    }
  };
  const getGraphData = useCallback(
    async (dataset: SearchListItem) => {
      try {
        // Fetch user details using the acl.users from the original Dataset interface
        const aclResponse = await Api.getUserDetailsByIds(dataset.users);
        const datasetSharedList: datasetSharedUser[] = dataset.users.map((id) => ({
          id,
          name: aclResponse[id] || id,
        }));

        const ownersName = await Api.getUserDetailsByIds(dataset.owners);
        const ownerList = dataset.owners.map((id) => ownersName[id] || id);

        //for local data fetch
        setOwnersData((prev) => ({ ...prev, [dataset._id]: ownerList }));
        setDatasetSharedUsersList((prev) => ({ ...prev, [dataset._id]: datasetSharedList }));

        //for redux data fetch
        dispatch(
          ownerDataSet({
            ownersDataList: { ...ownersData, [dataset._id]: ownerList },
          })
        );
        dispatch(
          sharedDataSet({
            sharedDataList: { ...datasetSharedUsersList, [dataset._id]: datasetSharedList },
          })
        );
      } catch (error) {
        enqueueSnackbar(t('snackbar.alertMessage.errorGetGraphData'));
        console.log(error);
      }
    },
    [dispatch, enqueueSnackbar, t, ownersData, datasetSharedUsersList]
  );
  const handleDatasetSelect = async (dataset: SearchListItem) => {
    if (activeNavItem !== t('welcomepage.Apps')) {
      await resetActiveItems(activeNavItem);
    }
    dispatch(
      selectedDataSet({
        activeDataSetName: dataset.name,
        activeDataSetId: dataset._id,
      })
    );
    dispatch(showUserActionContentOnChat(t('fileUpload.exisitingDatasetactiveMessage', { fileName: dataset.name })));
    if (!ownersData[dataset._id] || !datasetSharedUsersList[dataset._id]) {
      getGraphData(dataset);
    }
    //Executing Custom Apps Query when Custom Apps Dataset is selected
    if (activeNavItem === t('welcomepage.Apps')) {
      const sowresponse = await Api.fetchSowQuery(dataset._id, selectedCustomAppSkill as SkillId);

      dispatch(
        selectedCustomAppSkill === AppSkillId.AdvancedSummary
          ? saveAdvanceSummaryResponse(sowresponse.data as AdvanceSummaryResponse)
          : customAppsSkillIds.includes(selectedCustomAppSkill)
            ? saveSowCommitAiQueryResponse(sowresponse.data as SowCommitAiQueryResponse[])
            : saveSowPredictorQueryResponse(sowresponse.data as SowPredictorQueryResponse)
      );
    }
    if (isWelcomePage) {
      navigate(chatPageNavigation);
    }
  };

  const renderEditPopover = (dataset: SearchListItem) => {
    const handleOpenPopover = () => {
      if (!ownersData[dataset._id] || !datasetSharedUsersList[dataset._id]) {
        getGraphData(dataset);
      }
    };

    return (
      <BasicPopover
        srcComponent={<ImageButton srcFile={assets.dots} style={buttonStyle} />}
        onOpen={handleOpenPopover}
        contentComponent={
          <EditDataset
            activeDataSetName={dataset.name}
            activeDataSetId={dataset._id}
            currentDatasetId={dataset._id}
            currentDatasetName={dataset.name}
            isFromDatasetList={true}
            isItemVisible={false}
            ownerListDataset={ownersData[dataset._id] || []}
            sharedListDataset={datasetSharedUsersList[dataset._id] || []}
          />
        }
      />
    );
  };

  return (
    <Box>
      {loading ? (
        <CircularProgress size={20} sx={{ marginTop: '10px' }} />
      ) : (
        <>
          <SearchList
            label={t('rightPanel.labelName')}
            onSelectItem={handleDatasetSelect}
            showDetailsOnHover={true}
            items={datasets}
            renderPopover={renderEditPopover}
          />
        </>
      )}
    </Box>
  );
};
export default DatasetList;
