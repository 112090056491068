import { Box, List, ListItemButton, Typography, Tooltip } from '@mui/material';
import React from 'react';
import * as assets from '../../../../assets';
import { useTranslation } from 'react-i18next';
import BasicPopover from '../../../sharedComponents/BasicPopover';
import ImageButton from '../../../sharedComponents/ImageButton';
import EditAgent from './editAgent/EditAgent';
import { useAppSelector } from '../../../../reduxStore/redux-hooks';
import MyAgents, { lengthOfVisibleAgentOrTeamName, MyAgentsData } from './myAgents/MyAgents';
import { SectionDivider } from '../../../staticComponents/StaticHtmlGenerator';
import { buttonStyle } from '../dataset/Dataset';

function Agents() {
  const { t } = useTranslation();
  const { activeAgent } = useAppSelector((state) => state.agents);

  const renderEditActiveAgentPopover = (agent: MyAgentsData) => {
    return (
      <BasicPopover
        srcComponent={
          <Box className="hover-showDotsButton">
            <ImageButton srcFile={assets.dots} style={buttonStyle} />
          </Box>
        }
        contentComponent={<EditAgent agent={agent} isActiveAgent={true} />}
      />
    );
  };

  return (
    <Box id={'agent-2nd-level-content'}>
      <Box className="active-agent" marginBottom={'25px'}>
        <Typography color={'#6F6C6C'} fontWeight={700} fontSize={'14px'}>
          {t('rightPanel.agent.activeAgents')}
        </Typography>

        <Box>
          {!activeAgent.name ? (
            <Typography color={'#A6A6A6'} fontWeight={400} fontSize={'16px'} marginTop={'10px'}>
              {t('rightPanel.agent.noActiveAgents')}
            </Typography>
          ) : (
            <List>
              <ListItemButton
                key={activeAgent._id}
                className="agents-list"
                sx={{
                  padding: '5px',
                  paddingLeft: '0px',
                  width: '100%',
                }}
              >
                {/* <ListItemAvatar sx={{ alignSelf: 'start', minWidth: '50px' }}>
                  <Avatar className="avatar" alt="default agent" src={assets.ai360Agent} />
                </ListItemAvatar> */}
                <Box width={'100%'} overflow={'hidden'}>
                  <Tooltip
                    title={activeAgent.name.length > lengthOfVisibleAgentOrTeamName ? activeAgent.name : null}
                    arrow
                  >
                    <Typography
                      color={'#0B0C0C'}
                      fontWeight={700}
                      fontSize={'16px'}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                    >
                      {activeAgent.name}
                    </Typography>
                  </Tooltip>
                  <Typography color={'#50595F'} fontWeight={400} fontSize={'14px'}>
                    {activeAgent.model_name}
                  </Typography>
                </Box>
                <span className="buttonShowOnHover">{renderEditActiveAgentPopover(activeAgent)}</span>
              </ListItemButton>
            </List>
          )}
        </Box>
      </Box>

      <Box className="my-agents" marginBottom={'25px'}>
        <MyAgents />
      </Box>
    </Box>
  );
}

export default Agents;
