import { MyAgentsData } from '@/components/rightPanel/subpanel/agents/myAgents/MyAgents';
import { AIAssetSharedUserDetails } from '../../components/rightPanel/subpanel/teams/shareTeam/ShareTeam';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AgentActivationProcess {
  ShowAgentActivationDialogOnChat: boolean;
  activationAgent: MyAgentsData;
}

interface AgentSharedUsers {
  users: AIAssetSharedUserDetails[];
}

interface AgentsState {
  activeAgent: MyAgentsData;
  agentActivationProcess: AgentActivationProcess;
  agentSharedUsers: AgentSharedUsers;
  updateAgentDetails: MyAgentsData;
  isAgentToBeUpdated: boolean;
  refetchAgentsList: boolean;
}

const agentsInitialState = (): MyAgentsData => ({
  name: '',
  _id: '',
  description: '',
  model_name: '',
  temperature: 0.3,
  max_output_tokens: null,
  instructions: '',
  tools: null,
  dataset_id: null,
  type: '',
  owners: [],
  acl: { users: [] },
});

const initialState: AgentsState = {
  activeAgent: agentsInitialState(),
  agentActivationProcess: {
    ShowAgentActivationDialogOnChat: false,
    activationAgent: agentsInitialState(),
  },
  agentSharedUsers: {
    users: [],
  },
  updateAgentDetails: agentsInitialState(),
  isAgentToBeUpdated: false,
  refetchAgentsList: false,
};

export const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    activeAgentMethod: (state, action: PayloadAction<MyAgentsData>) => {
      state.activeAgent = action.payload;
    },
    agentActivationMethod: (state, action: PayloadAction<AgentActivationProcess>) => {
      state.agentActivationProcess = action.payload;
    },
    agentSharedUsers: (state, action: PayloadAction<AgentSharedUsers>) => {
      state.agentSharedUsers = action.payload;
    },
    updateAgent: (state, action: PayloadAction<MyAgentsData>) => {
      state.updateAgentDetails = action.payload;
    },
    confirmAgentUpdate: (state, action: PayloadAction<boolean>) => {
      state.isAgentToBeUpdated = action.payload;
    },
    refreshAgentsList: (state, action: PayloadAction<boolean>) => {
      state.refetchAgentsList = action.payload;
    },
    resetActiveAgent: () => ({ ...initialState, activeAgent: agentsInitialState() }),
    resetAgentActivationProcess: () => ({
      ...initialState,
      agentActivationProcess: {
        ShowAgentActivationDialogOnChat: false,
        activationAgent: agentsInitialState(),
      },
    }),
    resetUpdateAgentDetails: () => ({ ...initialState, updateAgentDetails: agentsInitialState() }),
  },
});

// Action creators are generated for each case reducer function
export const {
  activeAgentMethod,
  agentActivationMethod,
  agentSharedUsers,
  updateAgent,
  confirmAgentUpdate,
  refreshAgentsList,
  resetActiveAgent,
  resetAgentActivationProcess,
  resetUpdateAgentDetails,
} = agentsSlice.actions;

export default agentsSlice.reducer;
