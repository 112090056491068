export interface ValidationError {
  detail: {
    json: {
      [fieldName: string]: string[];
    };
  };
  message: string;
}

//Removed useCallback because getFormattedDateAndTime doesn’t need to be a hook, it doesn’t rely on any component state or lifecycle.
//We can simply define it as a regular function instead of using useCallback.
export const getFormattedDateAndTime = (last_updated: number) => {
  const date = new Date(last_updated * 1000);
  const day = date.getDate().toString().padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return {
    dateString: `${day} ${month} ${year}`,
    timeString: ` ${hours}:${minutes}`,
  };
};

export const validationErrorMessage = (error: ValidationError) => {
  if (error.detail.json) {
    // Access the json object where all the dynamic keys are stored
    const fields = error.detail.json;

    // Iterate through the field names in the json object
    for (const fieldName in fields) {
      const errorMessages = fields[fieldName];

      // Check if there are any error messages and return message
      if (errorMessages && errorMessages.length) {
        const [errorMessage] = errorMessages;
        return errorMessage || error.message;
      }
    }
  }
  // If no specific error messages, return the general message if available
  return error.message;
};
