import { Box, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AttachExistingDataset from './attach-existing-dataset/AttachExistingDataset';
import AttachNewDataset from './attach-new-dataset/AttachNewDataset';
import { ListOfExistingDataset } from '../../../dataset/DatasetModal';
import Api from '../../../../../../data/api/Api';
import { useSnackbar } from 'notistack';
import { AppSkillId } from '../../../../../../components/staticComponents/StaticHtmlGenerator';
import { useAppSelector } from '../../../../../../reduxStore/redux-hooks';
import { AgentType } from '../CreateAgent';

export interface AttachDataset {
  attachDataset: (datasetId: string) => void;
  setIsAgentCreateInProcess: (isInProcess: boolean) => void;
  selectedExistingDataset?: ListOfExistingDataset;
  attachDatasetTopK: (topKValue: number) => void;
}

function AttachDataset({ attachDataset, setIsAgentCreateInProcess, attachDatasetTopK }: AttachDataset) {
  const { t } = useTranslation();
  const [selectedDataset, setSelectedDataset] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const [datasetList, setDatasetList] = useState<ListOfExistingDataset[]>([]);
  const [isDatasetListLoading, setIsDatasetListLoading] = useState<boolean>(false);
  const { isAgentToBeUpdated, updateAgentDetails } = useAppSelector((state) => state.agents);

  useEffect(() => {
    fetchDatasets();

    //make existing dataset radio checked by default on edit agent.
    if (isAgentToBeUpdated && updateAgentDetails.type === AgentType.Dataset) {
      setSelectedDataset(t('fileUpload.existingDataset'));
    }
  }, []);

  // Function to fetch the list of existing datasets from the API
  const fetchDatasets = async () => {
    try {
      setIsDatasetListLoading(true);
      const listOfExistingDataset = (await Api.listDatasets(true, true, AppSkillId.DocCompletion))
        .data as ListOfExistingDataset[];
      setDatasetList(listOfExistingDataset);
      setIsDatasetListLoading(false);
    } catch (error) {
      enqueueSnackbar((error as Error).toString(), { variant: 'error' });
    }
  };

  return (
    <Box sx={{ padding: '0px 35px' }}>
      {isDatasetListLoading ? (
        <CircularProgress />
      ) : (
        <>
          {datasetList.length > 0 && (
            <>
              <Typography sx={{ fontWeight: 700, color: '#0B0C0C' }}>
                {t('rightPanel.agent.createAgent.attachDataset.datasetSelection')}
              </Typography>

              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="attach-dataset-row-radio-buttons-group-label"
                  name={t('fileUpload.radioGroup')}
                  sx={{ display: 'flex', gap: '2rem' }}
                  onChange={(event) => setSelectedDataset(event.target.value)}
                  defaultValue={isAgentToBeUpdated && t('fileUpload.existingDataset')}
                >
                  <FormControlLabel
                    value={t('fileUpload.existingDataset')}
                    control={<Radio />}
                    label={t('fileUpload.existingDataset')}
                  />
                  <FormControlLabel
                    value={t('fileUpload.newDataset')}
                    control={<Radio />}
                    label={t('fileUpload.newDataset')}
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}

          {selectedDataset === t('fileUpload.existingDataset') && (
            // Render the AttachExistingDataset component if the user selects an existing dataset
            <AttachExistingDataset
              attachDataset={attachDataset}
              setIsAgentCreateInProcess={setIsAgentCreateInProcess}
              attachDatasetTopK={attachDatasetTopK}
            />
          )}
          {(selectedDataset === t('fileUpload.newDataset') || !datasetList.length) && (
            // Render the AttachNewDataset component if the user selects a new dataset or no datasets are available
            <AttachNewDataset
              attachDataset={attachDataset}
              setIsAgentCreateInProcess={setIsAgentCreateInProcess}
              attachDatasetTopK={attachDatasetTopK}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default AttachDataset;
